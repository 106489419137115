App.Inspections = (function () {

  var initEdit = function() {
    selectInputOnClick();
  }
  var initUpdateQuantities = function() {
    $('#inspection-form-button').on('click', qtyUpdateHandler);
    selectInputOnClick();
  };

  var qtyUpdateHandler = function(evt) {
    drawFromOverageModal(evt);
  };

  var drawFromOverageModal = function(evt) {
    var quantity_to_inspect = intOrZero($('#quantity_to_inspect').val());
    var form_passed = intOrZero($('#item_qty_pass').val());
    var form_failed = intOrZero($('#item_qty_fail').val());
    var available_overage = intOrZero($('#available_overage_quantity').val())

    var replace_quantity = Math.min.apply(Math, [quantity_to_inspect - form_passed, form_failed, available_overage]);

    // Modal instructing warehouse to restock failures from overage
    if (replace_quantity > 0) {
      vex.dialog.confirm({
        unsafeMessage: "There are over-shipped items available.<br><br>" +
          "Please take <strong>" + replace_quantity + "</strong> items from the Return Shelf and bin them for inspection.<br><br>",
        buttons: [
          $.extend({}, vex.dialog.buttons.YES, {
            text: 'OK, I replaced ' + replace_quantity + ' items'
          }), $.extend({}, vex.dialog.buttons.NO, {
            text: 'Cancel'
          })],
        callback: function(value) {
          if (value) {
            inspectionCompleteModal(evt);
          }
        }
      });
      evt.preventDefault();
    } else {
      inspectionCompleteModal(evt);
    }
  }

  var inspectionCompleteModal = function(evt) {
    if (!$('#item_is_complete').is(":checked")) {
      $('#inspection-form').submit();
      // Don't warn if the complete checkbox is unchecked
      return;
    }

    var quantity_to_inspect = intOrZero($('#quantity_to_inspect').val());
    var sum = _.reduce([ $('#item_qty_pass').val(),
                         $('#item_qty_fail').val() ], function (sum, numStr) {
                           return intOrZero(numStr) + sum;
                         }, 0); // Use accum to avoid sum being a string initially

    if (sum != quantity_to_inspect) {
      evt.preventDefault();
      var message = "Are you sure inspection is complete?<br><br>" +
        "The total pass + fail quantity is different than the expected slip quantity.  " +
        "This item will no longer be able to be inspected if you mark it as complete."
      var buttons = {
        YES: {
          text: "Yes I'm sure,  I've inspected all items.",
          type: 'submit',
          className: 'vex-dialog-button-primary',
          click: function($vexContext, event) {
            $("#inspection-form").submit();
          }
        },
        NO: {
          text: "I'm not sure.",
          type: 'button',
          className: 'vex-dialog-button-secondary',
          click: function($vexContent, event) {
            return vex.close($vexContent.data().vex.id);
          }
        }
      }
      vex.dialog.confirm({
        unsafeMessage: message,
        buttons: [buttons.YES, buttons.NO]
      });
    } else {
      $('#inspection-form').submit();
    }
  };

  var intOrZero = function(str) {
    if (str.match(/^\d+$/)) {
      return parseInt(str);
    } else {
      return 0;
    }
  };

  var selectInputOnClick = function() {
    $("input[type='text']").on('click', function () {
      $(this).select();
    });
  }

  return {
    initEdit: initEdit,
    initUpdateQuantities: initUpdateQuantities
  };
})();
