App.OrderHoldItems = (function () {
  function init() {
    initActions();
  }

  var formContent = ""
  var saveBtn = ""
  var saveBtn2 = ""

  function initActions() {
   selectHoldItem();
   saveBtn = document.querySelector('#save-btn')
   saveBtn2 = document.querySelector('#save-btn_pend')
   formContent = document.querySelector('#form-input-placeholder')
  }

  function selectHoldItem(){
    let tds = document.querySelectorAll('tbody>tr')
    tds.forEach(e => e.addEventListener('click',function(f){
      e.querySelector('input[type="checkbox"]').toggleAttribute('checked');
      checkForClicked()
    }));
  }

  function checkForClicked(){
    /* Remove all appended inputs */
    formContent.innerHTML = ""

    let checkedCount = 0
    let checkboxes = document.querySelectorAll('tbody input[type="checkbox"]');

    let selectedItems = [];

    checkboxes.forEach(checkbox => {
      if(checkbox.checked){
        checkedCount = checkedCount + 1
        formContent.innerHTML += checkbox.parentNode.querySelector('.li_id').outerHTML
      }
    });


    if(checkedCount){
      saveBtn2.classList.remove('hidden')
      saveBtn.classList.remove('hidden')
    }else{
      saveBtn2.classList.add('hidden')
      saveBtn.classList.add('hidden')
    }

  }

  return {
    init: init
  }
})();
