import select2 from 'select2';

App.Order = (function () {
  function initShow() {
    // enable charge / refund form
    $(document).on("click", "[data-action=open-form]", function(e) {
      e.preventDefault();
      url = $(this).data('url');
      openForm(url);
    })

    //print functionality for address only print
    const sa = document.getElementById('shippingAddress');

    if(sa != undefined ){
      sa.addEventListener('click', function(e){
        e.preventDefault();
        printDiv()
      })
      function printDiv() {    
        var divToPrint = sa
        var popupWin = window.open('', '_blank', 'width=500,height=400');
        popupWin.document.open();
        popupWin.document.write('<html><style>*{font-family:sans-serif; font-size:16px}@page { size: auto;  margin: 0mm; }body{margin:1rem;padding:0;}li{list-style:none;line-height:1.5rem;}</style><body onload="window.print()">' + divToPrint.innerHTML + '</html>');
        popupWin.document.close();
      }
    }

    $("select").select2();
    // $("#update_catalog_received_section").hide();
    // $("#hide_catalog_received_btn").hide();

    // $("#update_catalog_received_btn").on("click", function(e) {
    //   $("#update_catalog_received_section").show();
    //   $("#hide_catalog_received_btn").show();
    //   $("#update_catalog_received_btn").hide();
    // });

    // $("#hide_catalog_received_btn").on("click", function(e) {
    //   $("#update_catalog_received_section").hide();
    //   $("#hide_catalog_received_btn").hide();
    //   $("#update_catalog_received_btn").show();
    // });

    $(document).on("click", "#restock_returned_qty", function(event){
      var order_id = $("#order_id").val();
      var li_length_id = $("#li_length_id").val();
      var value_arr = [];
      var grp_lis = $(this).parent().find('.grp_lis');
      var checkbox_list = grp_lis.find('[id^="check_"]');
      var checkbox_selected = false;
      for(var i = 0; i < checkbox_list.length; i++) {
        if (checkbox_selected == false) {
          checkbox_selected = checkbox_list[i].checked;
        }
      }

      if (checkbox_selected) {
        for(var i = 0; i < li_length_id; i++) {
          var value_hash = {};
          if (grp_lis.find('[id^="check_"]')[i].checked) {
            value_hash['li_id'] = grp_lis.find('[id^="item_code_id_"]')[i].id.split('_').slice(-1)[0];
            value_hash['item_code'] = grp_lis.find('[id^="item_code_id_"]')[i].value;
            value_hash['item_location'] = grp_lis.find('[id^="item_location_id_"]')[i].value;
            value_hash['returned_qty'] = grp_lis.find('[id^="returned_qty_id_"]')[i].value;
            value_hash['li_move_cart_id'] = grp_lis.find('[id^="li_move_cart_id_"]')[i].value;
            value_arr.push(value_hash);
          }
        }
        $.ajax({
          type: "POST",
          url: '/orders/restock_returned_li_qty',
          data: { order_id: order_id, value_arr: value_arr},
          success: function(response) {
            $(".stock_shelf_close").click();
            alert('Restocked successfully');
            window.location.reload();
          }
        });
      } else {
        alert("Please select the line item to restock the quantity.")
      }
    });

    App.OrderNote.init();
  }

  function openForm(url) {
    $.get(url, function(data) {
      var form = $(data);
      vex.open({
        unsafeContent: form,
        overlayClosesOnClick: false
      });
    });
  }

  return {
    initShow: initShow
  }
})();
