App.PurchaseOrderPolicy = (function () {
  function initEdit() {
    initAddRowButtons();
    initDeleteRowButtons();
  }

  function initAddRowButtons() {
    $('[data-action=add-row]').click(function(e){
      var $this = $(this);
      var template = $($this.data("template")).html();
      template = template.replace(/FORM_KEY/g, $this.data("form-key"));
      $($this.closest("td").find(".row:last")).after(template);
    });
  }

  function initDeleteRowButtons() {
    $(document).on('click', '[data-action=delete-closest]', function(e){
      var $this = $(this);
      var el = $this.closest($this.data('selector'));
      el.remove();
    });
  }

  return {
    initEdit: initEdit,
  }
})();
