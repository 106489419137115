App.Alert = (function() {
  function show(msg, type) {
    var tmpl = $(template());
    var alertClass = null;
    var fadeOut = true;
    if (type == 'notice') {
      alertClass = 'alert-success';
    } else {
      alertClass = 'alert-danger';
      fadeOut = false;
    }
    tmpl.addClass(alertClass);
    tmpl.append(msg);
    $(tmpl).on('click', '.close-button', function(e) {
      e.preventDefault();
      $(this).closest('.alert').remove();
    });
    $('body').append(tmpl);
    if (fadeOut) {
      tmpl.delay(4000).fadeOut(2000);
    }
  }

  function template() {
    return '<div class="flash-alert alert" role="alert">' +
      '<a href="#" class="close-button">&#215;</a>' +
      '</div>';
  }

  return {
    show: show
  }
})();
