App.ItemLocation = (function () {
  function initIndex() {
    App.ItemLocation.initActions();
  }

  function initShow() {
    App.ItemLocation.initActions();
  }

  function initActions() {
    $(document).on('click', '[data-action="open-item-location-form"]', function(e) {
      e.preventDefault();
      openForm($(this));
    });
  }

  function openForm(el) {
    var width = el.data("width") || "650px";
    var url = el.data("url");
    $.get(url, function(data) {
      var form = $(data);
      initForm(form);
      vex.open({
        unsafeContent: $('<div/>').append(form).html(),
        contentCSS: {width: width},
        overlayClosesOnClick: false
      });
    });
  }

  function initForm(form) {
    initMoveForm(form);
    initMoveInventoryForm(form);
    initAddLocationForm(form);
  }

  function initMoveForm(form) {
    $(document).on('click', '[data-action=toggle-element]', function(e) {
      e.preventDefault();
      var el = $(this);
      var show = $(el.data("show"));
      var hide = $(el.data("hide"));
      var schedule = el.data("schedule");
      show.show();
      hide.hide();
      $("#schedule-field").val(schedule);
    });
  }

  function initMoveInventoryForm(form) {
    // watch the quantity
    App.watch("#item-location-move-inventory-form #item-quantity", function(el) {
      var qty = parseInt(el.val()) || 0;
      var from = parseInt(form.find("#from-row .before").text());
      var to = parseInt(form.find("#to-row .before").text());
      form.find("#from-row .after").text(from - qty);
      form.find("#to-row .after").text(to + qty);
    });
  }

  function initAddLocationForm(form) {
    var $form = $(form);
    var initForm = function(e) {
      var locationType = $form.find("#item-location-type");
      var $max = $form.find('#max-quantity');
      var $maxCols = $form.find('.max-quantity-col');
      var $rs = $form.find('#replenish-sequence');
      var $rsCols = $form.find('.replenish-sequence-col');
      if (locationType.val() == '1') {
        $max.prop('disabled', false);
        $rs.prop('disabled', true);
        $maxCols.show();
        $rsCols.hide();
      } else {
        $max.prop('disabled', true);
        $rs.prop('disabled', false);
        $maxCols.hide();
        $rsCols.show();
      }
    }
    initForm();
    $form.on('change', '#item-location-type', initForm);
  }

  return {
    initActions: initActions,
    initIndex: initIndex,
    initShow: initShow
  }
})();
