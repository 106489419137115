/* eslint no-console: 0 */

import Vue from 'vue/dist/vue.esm'
import VueTruncate from 'vue-truncate-filter'
import {
  DraggableTree
} from 'vue-draggable-nested-tree/dist/vue-draggable-nested-tree.min.js'
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);
Vue.use(VueTruncate);

//replace al instance os imgix.net with //cdn.shopify.com/s/files/1/0384/0921/files/


window.App.Collection = (function() {
  function init() {

    const th = treeHelper;
    const app = new Vue({
      el: "#navigation-tree",
      components: {
        Tree: DraggableTree
      },
      data: {
        imgpre: '//cdn.shopify.com/s/files/1/0384/0921/files/',
        data: [],
        draggingID: -1,
        dragFrom: {},
        debug: false,
        showmodal: false,
        currenctSelectedNode: null,
        showClose: false,
        dragStartTree: "",
        dragEndTree: "",
        productCount: 0,
        modalMessage: "",
        csrf: "",
        selectedNode: {},
        dSelected: {},
        renderDebug: false,
        selectedCheck: false,
        file: null,
        showSave: false,
        customToolbar: [
          ["bold", "italic", "underline"],
          [{
            list: "ordered"
          }, {
            list: "bullet"
          }],
          ["link"]
        ],
        showSync: false,
        collectionData: [],
        notinnav: [],
        allCollections: [],
        debugChanges: [],
        items:[],
        unpublished: [],
        extraCollections: [],
        savedChangesTree: []
      },
      filters: {
        s3imgpath: function(value) {
          if (!value) return ''
          value = value.toString()
          var n = value.lastIndexOf('/');
          let filename = value.substring(n + 1);
          return ('//cdn.shopify.com/s/files/1/0384/0921/files/' + filename);
        }
      },
      methods: {
        checkedShopifyExist(n){
          fetch('https://www.80stees.com/collections/' + n.handle + '.json')
            .then(response => {
              return response.json();
            })
            .then(data => {
              app.selectedNode.shopify_collection_id = data.collection.id;
            });
        },
        uploadFile(e, n) {

          let imgtype = e.target.name;

          const saveModal = document.getElementById('save-modal');

          let newimg = e.target.files[0];
          const onDrop = (event) => {
            event.preventDefault()
            const files = event.dataTransfer.files;
            Array.from(files).forEach(file => uploadFile(file))
          }
          let url = e.target.dataset.directUploadUrl

          let imgprefix = "";
          if (e.target.dataset.image_prefix && e.target.dataset.image_prefix != "") {
            imgprefix = e.target.dataset.image_prefix;
          }

          var fd = new FormData();
          // var blob = new Blob(newimg);
          fd.append("blob", newimg);
          fd.append("image_path", e.target.dataset.upload_path);
          fd.append("image_prefix", imgprefix);
          fd.append("file_name", newimg.name);

          var xhr = new XMLHttpRequest();
          xhr.upload.addEventListener('progress', onprogressHandler, false);
          xhr.open('POST', '/collections/upload_image', true);
          xhr.setRequestHeader('X-CSRF-TOKEN', app.csrf);
          xhr.onreadystatechange = function() {
            if (xhr.readyState == XMLHttpRequest.DONE) {
              var response = xhr.response;
              var data = JSON.parse(response);
              n[imgtype] = data.path;
              app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey md:mt-8 mb-4">Successfully Uploaded</h2>';
              setTimeout(function() {
                saveModal.classList.add('hidden')
              }, 1250);
              this.showSave = true;
              this.showSync = false;
            }
          }
          xhr.send(fd);


          function onprogressHandler(evt) {
            app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey md:mt-8 mb-4">Uploading Image  <div class="loading-dots"><div class="dot"></div><div class="dot"></div><div class="dot"></div></div></h2>';
            saveModal.classList.remove('hidden');
          }
        },


        // add child to main tree
        addChild() {
          app.collectionData.push({
            title: "New Collection",
            parent_id: 0,
            isnew: true,
            published: true,
            description: null
          });
          let newChild = app.collectionData[app.collectionData.length - 1];
          app.curNodeClicked(newChild, 'new');
          app.changeCollection(newChild);
        },
        addChildPublished() {
          app.notinnav.push({
            title: "New Collection",
            parent_id: 0,
            isnew: true,
            published: true,
            innav: false,
            description: null
          });
          let newChild = app.notinnav[app.notinnav.length - 1];
          app.curNodeClicked(newChild, 'new');
          app.changeCollection(newChild);
        },
        addChildUnpublished() {
          app.unpublished.push({
            title: "New Collection",
            parent_id: 0,
            isnew: true,
            published: false,
            innav: false,
            description: null
          });
          let newChild = app.unpublished[app.unpublished.length - 1];
          app.curNodeClicked(newChild, 'new');
          app.changeCollection(newChild);
        },
        removeNode(n) {
          n.published = false;
          n.innav = false;
          n._vm.store.deleteNode(n);
          app.selectedCheck = false;
        },
        showDebug(){

          let notree = ({ children = [], ...data}) => [data, ...children.flatMap(notree)];

          let fmnt = app.collectionData.flatMap(notree);

          let ta = [];
          let allflat = [];

          app.$children.forEach(function(e) {
            if (e.store) {
              ta.push(e.store.getPureData());
            }
          });

          ta.forEach(function(tree) {
            allflat.push(tree.flatMap(notree));
          });

          var fltmrg = [...allflat[0],...allflat[1]];
          app.allCollections = fltmrg;
          app.items = fltmrg;

          //catch any missing collections and add them to debug
          for(let c of app.extraCollections){
            if(!app.items.find(obj => obj.id == c.id)){
              console.log('not found');
              console.dir(c);
              app.items.push(c);
            }
          }


          app.debug ? app.debug = false : app.debug = true;


        },
        filterDebug() {
          // Declare variables
          var input, filter, ul, li, a, i, txtValue;
          input = document.getElementById('debugFilter');
          filter = input.value.toUpperCase();
          ul = document.getElementsByTagName("table")[0];
          li = ul.querySelectorAll('tbody>tr');

          // Loop through all list items, and hide those who don't match the search query
          for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("td")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
            } else {
              li[i].style.display = "none";
            }
          }
        },
        unpublishCollection(n) {
          if (n.children.length > 0) {
            this.modalMessage = "<p>Sorry, you can't unpublish a parent collection, remove children and try again</p>";
            const saveModal = document.getElementById('save-modal');
            saveModal.classList.remove('hidden');
            setTimeout(function() {
              saveModal.classList.add('hidden')
            }, 2500);
          } else {
            if(n.parent.isRoot){
              app.collectionData[0].updateBaseTree = true;
              app.updateBase(app.collectionData[0]);
            }
            let curNode = {};
            n.published = false;
            curNode.published = false;
            curNode.id = n.id;
            n.changed = true;
            n.innav = false;
            n.changePublish = true;
            n.class = "unpublished";
            app.selectedCheck = false;
            n._vm.store.deleteNode(n);
            let obj = {
              collection: {}
            }
            obj.collection = curNode;
            fetch("/collections/" + n.id + '.json', {
              method: 'PUT',
              headers: new Headers({
                'X-CSRF-TOKEN': app.csrf,
                'Content-Type': 'application/json'
              }),
              body: JSON.stringify(obj)
            })
            .then(response => {
              let data = {
                smart_collection:{
                  id: n.shopify_collection_id,
                  published: false
                }
              }
              fetch("/collections/update_shopify_collection", {
                 method: 'PUT',
                 headers: new Headers({
                   'X-CSRF-TOKEN': app.csrf,
                   'Content-Type': 'application/json'
                 }),
                 body: JSON.stringify(data)
               })
            })
            app.treeChange(n,n._vm.$parent.store, 'unpub');
          }
        },
        metaChange(collection) {
          app.showSave = true;
          collection.metaChange = true;
        },
        collectionChange(collection) {
          app.showSave = true;
          collection.collectionChange = true;
        },
        menuChange(collection) {
          app.showSave = true;
          if (collection._vm.store.$el.id == 'navtree') {
            collection.menuChange = true;
          }
        },
        publishCollection(n) {
          n.published = true;
        },
        updateTree() {
          // console.log('update tree called');
        },
        expandAll() {
          th.breadthFirstSearch(this.collectionData, node => {
            node.open = true;
          });
        },
        collapseAll() {
          th.breadthFirstSearch(this.collectionData, node => {
            node.open = false;
          });
        },
        showCollections() {
          app.selectedCheck = false;
        },
        scrollDown(element) {
          let scrollElement = document.querySelector(element);
          let scrollHeight = scrollElement.scrollHeight;
          scrollElement.scroll({
            top: scrollHeight,
            left: 0,
            behavior: 'smooth'
          });
        },
        scrollUp(element) {
          let scrollElement = document.querySelector(element);
          let scrollHeight = scrollElement.scrollHeight;
          scrollElement.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        },
        dragstart(node) {
          app.dragStartTree = node._vm.store.$el.id;
          if(node.parent.isRoot){
            app.dragFrom = node.parent;
            app.draggingID = 0;
          }
          if(node.parent_id !== undefined){
            app.draggingID = node.parent_id;
            if(node._vm.store.$el.id=='navtree'){
              app.dragFrom = node.parent;
            }
          }
        },
        selectNode(data){
          //console.dir(data);
          data._vm.store.dplh.parent = data._vm.data.parent;
          app.selectedNode = data;
        },
        dragend(node) {
          let thisNode = node._vm.store.dplh;
          node.parent_id = thisNode.parent.id;
          if(node.parent_id !== app.draggingID){
            setTimeout(function() {
              if(node.parent.isRoot){
                node.updateBaseTree = true;
                app.updateBase(node);
              }else if(app.dragFrom.isRoot && app.dragStartTree != 'notinnav'){
                node.updateBaseTree = true;
                app.updateBase(node.parent);
              }
              app.dragFrom.menuChange = true;
              app.dragFrom.childArray = [];
              app.dragFrom.children.forEach(function(child, index) {
                app.dragFrom.childArray.push({
                  id: child.id,
                  position: index
                });
              });
              app.dragFrom.orderChanged = true;
            },300);
          }
          app.dragEndTree = node._vm.store.$el.id;
          app.draggingID = -1;
        },
        changeCollection(node) {
          this.showSync = false;
          app.selectedCheck = true;
          setTimeout(function() {
            var newTitle = document.getElementById('collection-input-title');
            newTitle.select();
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }, 200);
        },
        debugClicked(data){
          app.dSelected = data;
        },
        debugChange(data){
          let foundIndex = app.debugChanges.findIndex(x => x.id === data.id);
          if(foundIndex!= -1){
            app.debugChanges[foundIndex] = data
          }else{
            app.debugChanges.push(data);
          }
          console.dir(app.debugChanges);
        },
        debugToggleStates(c,t){
          if(c[t] === false){
            c[t] = true;
          }else{
            c[t] = false;
          }
        },
        saveDebug(){
            for(let c of app.debugChanges){
              putRequest(c.id, c);
              function putRequest(id, data) {
                fetch("/collections/" + id + '.json', {
                    method: 'PUT',
                    headers: new Headers({
                      'X-CSRF-TOKEN': app.csrf,
                      'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(data)
                  })
                  .then(response => {
                    // console.dir(response)
                  })
              }
            }
        },
        curNodeClicked(data, isnew) {
          app.selectedNode = data;
          app.showSave = true;
          //get product count in collection
          if (isnew != 'new') {
            //grab current collection product count from 80stees
            fetch('https://www.80stees.com/collections/' + app.selectedNode.handle + '.json')
              .then(response => {
                return response.json();
              })
              .then(data => {
                // Work with JSON data here
                this.productCount = data.collection.products_count;
                //console.dir(app.selectedNode);
              })
              .catch(err => {
                // Do something for an error here
              })
          }
        },
        saveChanges(showmodal, directNode) {
          
          
            var els = document.querySelectorAll('a[href^="/collections/null"]');
            for (var i = 0, l = els.length; i < l; i++) {
                let t = els[i].text;
                t = t.trimStart();
                t = t.replace(/\s/g, '-');
                t = t.replace(/'/g, '');
                els[i].href = '/collections/'+t;
            }

          if (directNode != null) {
            let obj = {
              collection: {}
            }
            obj.collection = directNode;
            putRequest(directNode.id, obj);
          } else {
            const untree = ({
              children = [],
              ...data
            }) => [data, ...children.flatMap(untree)];

            let treeArray = [];
            let flattened = [];
            let flatTree = [];

            app.$children.forEach(function(e) {
              if (e.store) {
                treeArray.push(e.store.getPureData());
              }
            });
            treeArray.forEach(function(tree) {
              flattened.push(tree.flatMap(untree));
            });

            for (var i = 0; i < flattened.length; i++) {
              for (var ii = 0; ii < flattened[i].length; ii++) {
                if(flattened[i][ii].published == true){
                  flatTree.push(flattened[i][ii]);
                }
              }
            }

            app.savedChangesTree = flatTree;
            for (let i = 0; i < app.savedChangesTree.length; i++) {
              if (app.savedChangesTree[i].childArray != undefined || app.savedChangesTree[i].updateBaseTree) {
                updateTreeOrder(app.savedChangesTree[i]);
              }

              if (app.savedChangesTree[i].changed == true || app.savedChangesTree[i].changePublish == true) {
                let obj = {
                  collection: {}
                }
                obj.collection = app.savedChangesTree[i];
                if (app.savedChangesTree[i].id != null) {
                  putRequest(app.savedChangesTree[i].id, obj);
                } else {
                  fetch("/collections/", {
                      method: 'POST',
                      headers: new Headers({
                        'X-CSRF-TOKEN': app.csrf,
                        'Content-Type': 'application/json'
                      }),
                      body: JSON.stringify(obj)
                    })
                    .then(function(response) {
                      return response.json();
                    }).then(function(data) {
                      let dbid = data.id;
                      app.savedChangesTree[i].id = dbid;
                    });
                }
                //sets changed to false after save to avoid multiple repeated save calls
                // app.savedChangesTree[i].changed = false;
              }
            }
          }


          function putRequest(id, data) {
            fetch("/collections/" + id + '.json', {
                method: 'PUT',
                headers: new Headers({
                  'X-CSRF-TOKEN': app.csrf,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify(data)
              })
              .then(response => {
                // console.dir(response)
              })
          }

          function updateTreeOrder(tc) {
            //tree order change
            let obj = {
              collections: {}
            }
            if(tc.updateBaseTree){
              tc.id = 0;
              obj.collections = app.collectionData[0].parent.childArray
            }else{
              obj.collections = tc.childArray
            }

            // console.dir(obj);
            fetch("/collections/" + tc.id + "/update_children", {
                method: 'POST',
                headers: new Headers({
                  'X-CSRF-TOKEN': app.csrf,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify(obj)
              })
              .then(function(response) {
                // console.log(response);
              })
          }

          //show save modal and scroll to top
          if (showmodal === true) {
            app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey md:mt-8 mb-4">Changes Saved!</h2>';
            const saveModal = document.getElementById('save-modal');
            saveModal.classList.remove('hidden');
            //setTimeout(function(){ saveModal.classList.add('hidden')}, 1750);
          }
          //hide save button and show sync to shopify button
          this.showSave = false;
          this.showSync = true;
        },
        treeChange(node, targetTree, trigger) {

          //get id of tree that node is dropped into
          this.showSync = false;
          let treeID = "";
          if (targetTree != undefined) {
            treeID = targetTree.$attrs.id;
          }
          // set proper values for each node based on where it is dropped
          if(trigger == 'unpub'){
            node.published = false;
            node.changePublish = true;
            node.parent.menuChange = true;
            if(treeID == 'navtree'){
              node.menuChange = true;
              node.parent.childArray = [];
              node.parent.children.forEach(function(child, index) {
                if(child.published == true){
                  node.parent.childArray.push({
                    id: child.id,
                    position: index
                  });
                }
                node.parent.orderChanged = true;
              });
            }
            app.saveChanges(false, null);
            app.showSave = false;
            app.showSync = false;
          }
          else{
            if (treeID == 'unpublished') {
              if(node.published == true){
                node.published = false;
                node.changePublish = true;
              }
              if(node.innav == true){
                node.innav = false;
              }
            } else if (treeID == 'notinnav') {
              if(node.innav == true){
                node.innav = false;
                node.changed = true;
              }
              if(node.published == false){
                node.changePublish = true;
                node.published = true;
              }
            } else if (treeID == 'navtree') {
              //in navigation tree
              if(node.published == false){
                node.changePublish = true;
                node.published = true;
              }
              if(node.innav == false){
                  node.innav = true;
                  node.changed = true;
              }
              //node.menuChange = true;
              node.parent.childArray = [];
              node.parent.children.forEach(function(child, index) {
                if(child.published == true){
                  node.parent.childArray.push({
                    id: child.id,
                    position: index
                  });
                }
                node.parent.orderChanged = true;
              });
            }
          }
          app.showSave = true;
        },
        drag(node) {
          // console.dir(node);
        },
        loadTreeData() {
          function getMetaContents(e){var t=document.getElementsByTagName("meta");for(var n in t)if(t[n].name==e)return t[n].content}
          let cf = getMetaContents('csrf-token');
          this.csrf = cf;
          let self = this;

          fetch("/collections.json", {
              method: 'GET',
              headers: new Headers({
                'X-CSRF-TOKEN': this.csrf,
                'Content-Type': 'application/json'
              })
            })
            .then(response => {
              response.json().then(json => {
                //clone json to allow for different sort order position vs alphabetical
                JSON.clone = function(obj) {
                  return JSON.parse(JSON.stringify(obj));
                };
                let alph = JSON.clone(json);

                let sortAlph = alph;
                //grab parents from main json return
                const parentPositionArray = json[0].children.filter(child => child.position != 100)

                // sort parents by position
                parentPositionArray.sort(function(a, b) {
                  return a.position - b.position
                });
                const parentOrderMapId = parentPositionArray.map(parent => parent.id);

                function mapOrder(array, order, key) {
                  const farray = array.filter(function(item) {
                    return order.indexOf(item.id) !== -1;
                  });
                  farray.sort(function(a, b) {
                    var A = a[key],
                      B = b[key];
                    if (order.indexOf(A) > order.indexOf(B)) {
                      return 1;
                    } else {
                      return -1;
                    }
                  });
                  return farray;
                };

                const ordered_array = mapOrder(json, parentOrderMapId, 'id');

                for (let p in ordered_array) {
                  let children = ordered_array[p].children;
                  let childPostArr = children.sort(function(a, b) {
                    return a.position - b.position
                  });
                  let childrenOrderMapId = childPostArr.map(child => child.id);
                  let jsonCpy = JSON.clone(json);
                  let child_ordered_array = mapOrder(jsonCpy, childrenOrderMapId, 'id');
                  ordered_array[p].children = child_ordered_array;
                }


                // trees to house the data
                let nin = [];
                let unpub = [];

                for (let i = 0; i < sortAlph.length; i++) {
                  let cc = sortAlph[i];
                  if(cc.published == false && cc.title != undefined){
                    unpub.push(cc);
                  }else if (cc.published == true && cc.innav == false && cc.title != undefined) {
                    if(cc.children.length > 0){
                      for(let c of cc.children){
                        if(c.title != undefined){
                          nin.push(c);
                        }
                      }
                    }
                    cc.children = [];
                    nin.push(cc);
                  }else{
                    app.extraCollections.push(cc);
                  }
                }

                //set app to show 2 flat trees, turn flat nav tree into nested tree below
                this.notinnav = nin;
                this.unpublished = unpub;

                //looping through fixes plugin issue
                for (let i of ordered_array) {
                  let cchildren = [];
                  if(i.children.length>0){
                    for(let child of i.children){
                      if(child.title != null){
                       if(child.parent_id == null){
                         child.parent_id = 0;
                       }
                       cchildren.push(child);
                      }
                    }
                  }
                  i.parent_id = 0;
                  i.children = cchildren;
                  app.collectionData.push(i);
                }
                //app.collectionData = unflat;
                setTimeout(function() {
                  for(let c of app.collectionData){
                    if(c.children.length){
                      for(let d of c.children){
                        d.parent_id = d.parent.id;
                      }
                    }
                  }
                  self.collapseAll();
                }, 300);
              })
            })

            let cns = null;
            let treeNodes = document.querySelectorAll('.tree-node');
            treeNodes.forEach(function(c){
              c.addEventListener('click',function(e){
                let ct = e.target.closest('.tree-node');
                //only allow for 1 at a time selection
                if(cns != null){cns.classList.remove('selected')}
                ct.classList.add('selected');
                cns = ct;
              });
            });

            function insertAndShift(arr, from, to) {
                let cutOut = arr.splice(from, 1) [0]; // cut the element at index 'from'
                arr.splice(to, 0, cutOut);            // insert it at index 'to'
            }

            (() => {
              window.addEventListener('keydown', e => {
                if(e.shiftKey || e.key.startsWith("Arrow")){
                if(app.selectedNode != ''){
                  //e.preventDefault();
                  var s = e.shiftKey;
                  var k = e.key;
                  var c = app.selectedNode;
                  if(c != undefined && app.selectedNode._vm.store != undefined){
                    var cn = app.selectedNode._vm.store.dplh;
                    var cnpc = cn.parent.children;
                    var cnpcl = cnpc.length;
                    var cnpci = cnpc.indexOf(c);
                  }
                  if(k === 'ArrowDown' && s){
                    // console.log('shift down')
                  }else if(k === 'ArrowUp' && s){
                    // console.log('shift up')
                  }else if(k === 'ArrowLeft' && s){
                    // console.log('shift left')
                  }else if(k === 'ArrowRight' && s){
                    // console.log('shift right');
                  }else if(k === 'ArrowDown'){
                    e.preventDefault();
                    if(cnpci<cnpcl-1){
                      app.showSave = true;
                      c.menuChange = true;
                      insertAndShift(cnpc,cnpci,cnpci+1);
                      cnpci = cnpci+1;
                      app.updateChildArray(c);
                      if(c.parent.isRoot){
                        c.updateBaseTree = true;
                        app.updateBase(c);
                      }
                    }
                  }else if(k === 'ArrowUp'){
                    e.preventDefault();
                    if(cnpci>0){
                      app.showSave = true;
                      cn.menuChange = true;
                      insertAndShift(cnpc,cnpci,cnpci-1);
                      cnpci = cnpci-1;
                      app.updateChildArray(c);
                      if(c.parent.isRoot){
                        c.updateBaseTree = true;
                        app.updateBase(c);
                      }
                    }
                  }else if(k === 'ArrowRight'){
                    // console.log('right');
                  }else if(k === 'ArrowLeft'){
                    if(c._vm.store.$el.id=='navtree'){
                      if(!c.parent.isRoot){
                        //remove child from array and add it after current parent
                        cnpc.splice(cnpci, 1);
                        let pindex = c.parent.parent.children.indexOf(c.parent);
                        c.parent.parent.children.splice( pindex+1, 0, c);
                        //update array on removed tree
                        app.updateChildArray(c);
                        c.parent = c.parent.parent;
                        app.showSave = true;
                        c.menuChange = true;
                        //update array on added to tree
                        app.updateChildArray(c);
                        if(c.parent.isRoot){
                          c.updateBaseTree = true;
                          app.updateBase(c);
                        }
                      }
                    }
                  }
                  
                }
              }
              });
            })();
            
            setTimeout(function(){
                  var els = document.querySelectorAll('a[href^="/collections/null"]');
                  for (var i = 0, l = els.length; i < l; i++) {
                      let t = els[i].text;
                      t = t.trimStart();
                      t = t.replace(/\s/g, '-');
                      t = t.replace(/'/g, '');
                      els[i].href = '/collections/'+t;
                  }
                  console.log('fixed links');
              }, 5000);

        },
        updateChildArray(c){
          c.parent.childArray = [];
          c.parent.children.forEach(function(child, index) {
            if(child.published == true){
              c.parent.childArray.push({
                id: child.id,
                position: index
              });
            }
            c.parent.orderChanged = true;
          });
        },
        updateBase(n){
          n.menuChange = true;
          let ca = [];
          let mainP = app.collectionData[0].parent;
          for(let i in mainP.children){
            ca.push({
              id: mainP.children[i].id,
              position: i
            });
          }
          mainP.childArray = ca;
        },
        closeModal() {
          const saveModal = document.getElementById('save-modal');
          saveModal.classList.add('hidden');
        },
        syncToShopify() {
          const saveModal = document.getElementById('save-modal');

          //************************
          //----- UPDATE MENU ------
          //************************
          function updateShopifyMenu() {
            //  This gives you a string representing that element and its content
            var els = document.querySelectorAll('a[href^="/collections/null"]');
            for (var i = 0, l = els.length; i < l; i++) {
                let t = els[i].text;
                t = t.trimStart();
                t = t.replace(/\s/g, '-');
                t = t.replace(/'/g, '');
                els[i].href = '/collections/'+t;
            }
            console.log('fixed null links');
            let value = document.getElementById('menutosync').innerHTML
            let data = {
              asset: {
                key: "snippets/main-nav-static.liquid",
                value
              }
            };
            //  This gives you a string in JSON syntax of the object above that you can
            let json = JSON.stringify(data);

            //now send this to controller via fetch
            fetch("/collections/update_shopify_menu", {
                method: 'PUT',
                headers: new Headers({
                  'X-CSRF-TOKEN': app.csrf,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify(data)
              })
              .then(response => {
                app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey md:mt-8 mb-4">Successfully Synced Menu</h2>'
                app.showSync = false;
                app.showSave = true;
                app.showClose = true;
              });

              value = [];
              console.dir(app.collectionData);
              for(let i=0;i< app.collectionData.length; i++){
                value.push({children:[]})
                for(let c of app.collectionData[i].children){
                  value[i].children += '<li class="collection-item"><a href="/collections/'+c.handle+'" class="is-flex-row"> <div class="col-45 svg"> <img nopin="nopin" alt="'+c.title+'" src="//cdn.shopify.com/s/files/1/0384/0921/files/'+c.navimage+'"></div> <span>'+c.title+'</span></a></li>';
                }
              }

              let jsonData = {
                asset: {
                  key: "assets/main-nav-json.js",
                  value
                }
              }
              fetch("/collections/update_shopify_menu", {
                method: 'PUT',
                headers: new Headers({
                  'X-CSRF-TOKEN': app.csrf,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify(jsonData)
              })
          }

          //******************************
          //----- UPDATE COLLECTIONS -----
          //******************************

          //trigger to determine if menu needs to be updated or not based on collection change loop below
          let doUpdateMenu = false;
          //loop through looking for new or updated collections
          for (let c in app.savedChangesTree) {
            let collection = app.savedChangesTree[c];
            if (collection.menuChange == true) {
              console.log('menu changed, here is the collection trigger:');
              console.dir(collection);
              doUpdateMenu = true;
            }

            if (collection.isnew == true && !collection.alreadyinshopify) {
              if (collection.published == true) {
                // console.log('new published collection');
                let data = {
                  smart_collection: {
                    title: collection.title,
                    handle: collection.handle,
                    body_html: collection.description,
                    rules: [{
                      column: "tag",
                      relation: "equals",
                      condition: collection.collectiontag
                    }],
                    "metafields": [{
                        "namespace": "fact",
                        "key": "text",
                        "value": collection.funfacts,
                        "value_type": "string"
                      },
                      {
                        "namespace": "navigation",
                        "key": "image",
                        "value": collection.navimage,
                        "value_type": "string"
                      },
                      {
                        "namespace": "banner",
                        "key": "dsk",
                        "value": collection.bannerimgdsk,
                        "value_type": "string"
                      },
                      {
                        "namespace": "banner",
                        "key": "mbl",
                        "value": collection.bannerimgmbl,
                        "value_type": "string"
                      },
                      {
                        "namespace": "search",
                        "key": "rules",
                        "value": collection.collectiontag,
                        "value_type": "string"
                      },
                      {
                        "namespace": "trademark",
                        "key": "logo",
                        "value": collection.trademarklogo,
                        "value_type": "string"
                      }
                    ],
                    "published": true
                  }
                }
                addCollectionFetch(data, collection);
              } else {
                // console.log('new unpublished collection');
                let data = {
                  smart_collection: {
                    title: collection.title,
                    handle: collection.handle,
                    body_html: collection.description,
                    rules: [{
                      column: "tag",
                      relation: "equals",
                      condition: collection.collectiontag
                    }],
                    "metafields": [{
                        "namespace": "fact",
                        "key": "text",
                        "value": collection.funfacts,
                        "value_type": "string"
                      },
                      {
                        "namespace": "navigation",
                        "key": "image",
                        "value": collection.navimage,
                        "value_type": "string"
                      },
                      {
                        "namespace": "banner",
                        "key": "dsk",
                        "value": collection.bannerimgdsk,
                        "value_type": "string"
                      },
                      {
                        "namespace": "banner",
                        "key": "mbl",
                        "value": collection.bannerimgmbl,
                        "value_type": "string"
                      },
                      {
                        "namespace": "search",
                        "key": "rules",
                        "value": collection.collectiontag,
                        "value_type": "string"
                      },
                      {
                        "namespace": "trademark",
                        "key": "logo",
                        "value": collection.trademarklogo,
                        "value_type": "string"
                      }
                    ],
                    "published": false
                  }
                }
                addCollectionFetch(data, collection);
              }
            } else if (collection.changed == true || collection.changePublish == true || collection.alreadyinshopify) {

              let data = {
                smart_collection:{
                  id: collection.shopify_collection_id,
                  sort_order: 'best-selling',
                  ...(collection.changeTitle && {title: collection.title}),
                  ...(collection.changeHandle && { handle: collection.handle}),
                  ...(collection.changeDescription && {body_html: collection.description}),
                  ...(collection.changePublish && {published: collection.published}),
                }
              }

              let sc = data.smart_collection;

              let mf = [];

              // console.log('changed collection');
              if(collection.changeFunFact){
                mf.push({
                  "namespace": "fact",
                  "key": "text",
                  "value": collection.funfacts,
                  "value_type": "string"
                })
              }

              if(collection.changeNavImg){
                mf.push({
                  "namespace": "navigation",
                  "key": "image",
                  "value": collection.navimage,
                  "value_type": "string"
                })
              }

              if(collection.changeDskImg){
                mf.push({
                  "namespace": "banner",
                  "key": "dsk",
                  "value": collection.bannerimgdsk,
                  "value_type": "string"
                })
              }

              if(collection.changeMblImg){
                mf.push({
                  "namespace": "banner",
                  "key": "mbl",
                  "value": collection.bannerimgmbl,
                  "value_type": "string"
                })
              }

              if(collection.changeTags){
                mf.push({
                  "namespace": "search",
                  "key": "rules",
                  "value": collection.collectiontag,
                  "value_type": "string"
                })
              }

              if(collection.changeTM){
                mf.push({
                  "namespace": "trademark",
                  "key": "logo",
                  "value": collection.trademarklogo,
                  "value_type": "string"
                })
              }

              if(collection.changeTags){
                sc.rules = [{column: "tag",relation: "equals",condition: collection.collectiontag}]
              }

              if(mf.length > 0){
                sc.metafields = mf;
              }

              updateCollectionFetch(data,collection);

            }
          }
          if (doUpdateMenu) {
            updateShopifyMenu();
          }

          function updateCollectionFetch(collection,currentCollection) {
            app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey md:mt-8 mb-4">Synching to Shopify <div class="loading-dots"><div class="dot"></div><div class="dot"></div><div class="dot"></div></div></h2>'
            saveModal.classList.remove('hidden');

            fetch("/collections/update_shopify_collection", {
                method: 'PUT',
                headers: new Headers({
                  'X-CSRF-TOKEN': app.csrf,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify(collection)
              })
              .then(response => {
                if (response.ok == true) {
                  app.selectedNode.changed = false;
                  app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey md:mt-8 mb-4">Success!</h2>';
                  app.showSync = false;
                  setTimeout(function() {
                    saveModal.classList.add('hidden')
                  }, 1200);
                } else {
                  app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey md:mt-8 mb-4">Looks like there was an issue</h2>'
                  setTimeout(function() {
                    saveModal.classList.add('hidden')
                  }, 2000);
                }
              })
          }

          function addCollectionFetch(data, collection) {
            app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey md:mt-8 mb-4">Synching to Shopify <div class="loading-dots"><div class="dot"></div><div class="dot"></div><div class="dot"></div></div></h2>';
            saveModal.classList.remove('hidden');
            fetch("/collections/add_shopify_collection", {
                method: 'POST',
                headers: new Headers({
                  'X-CSRF-TOKEN': app.csrf,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify(data)
              })
              .then(handleErrors)
              .then(function(response) {
                return response.json();
              }).then(function(data) {
                collection.shopify_collection_id = data.smart_collection.id;
                collection.changed = true;
                collection.isnew = false;

                // console.dir(collection);
                app.saveChanges(false, collection);
                //  console.log('saved changes for new collection synced to shopify');

                app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey mb-4">Successfully Synced To Shopify!</h2>'
                app.showSync = false;
                app.showSave = false;
                setTimeout(function() {
                  saveModal.classList.add('hidden')
                }, 1200);
              }).catch(function(error) {
                app.modalMessage = '<h2 class="text-4xl text-center font-bold text-grey mb-4">Looks like there was an issue</h2><p>Make sure this is not a duplicated collection.</p>'
                setTimeout(function() {
                  saveModal.classList.add('hidden')
                }, 3500);
              });

            function handleErrors(response) {
              if (!response.ok) throw Error(response.statusText);
              return response;
            }
          }
        },
        replaceSpace() {
          if (app.selectedNode.handle != undefined) {
            return app.selectedNode.handle
          } else {
            return app.selectedNode.title.replace(/ /g, "-");
          }
        }
      },
      mounted() {
        this.loadTreeData();
      }
    });
  }
  return {
    init: init
  }
})();
