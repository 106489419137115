import Vue from 'vue/dist/vue.esm'

window.App.Notifications = (function() {
function init() {

    var app = new Vue({
        el: '#app',
        data: {
            suppliers: [] 
        }
    })
    
    const myRequest = new Request('notification_reports.json');
    fetch(myRequest)
    .then(response => response.json())
    .then(data => {
        var sp = data;
        for(let s of sp){
            let unack = 0;
            let lfd = 0;
            let led = 0;
            let ltd = 0;
            let lod = 0;
            for(let i of s[1]){
                if(i.type=='dropship_order_unacknowledged'){
                    unack = unack+1;
                }
            }
            for(let o of s[1]){
                if(o.days_open <= 5){
                    lfd = lfd + 1;
                }else if(o.days_open >5 && o.days_open <= 8){
                    led = led + 1;
                }else if(o.days_open >8 && o.days_open <= 12){
                    ltd = ltd + 1;
                }else if(o.days_open >12){
                    lod = lod + 1;
                }
            }
            s[0].unacknowledged_count = unack;
            s[0].last_five_days = lfd;
            s[0].last_eight_days = led;
            s[0].last_twelve_days = led;
            s[0].over_twelve_days = lod;

            app.suppliers.push(s);
        }
    });

    //date maths
    Date.prototype.yyyymmdd = function() {
        var mm = this.getMonth() + 1;
        var dd = this.getDate();
        return [this.getFullYear(),
                (mm>9 ? '' : '0') + mm,
                (dd>9 ? '' : '0') + dd
               ].join('-');
      };
      let dmf = new Date();
      dmf.setDate(dmf.getDate()-5);
      app.dmf = dmf.yyyymmdd();
      let dme = new Date();
      dme.setDate(dme.getDate()-8);
      app.dme = dme.yyyymmdd();
      let dmt = new Date();
      dmt.setDate(dmt.getDate()-12);
      app.dmt = dmt.yyyymmdd();
    
}
return {
    init: init
}

})();