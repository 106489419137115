import { fabric } from 'fabric';

App.ProductImageCrop = (function () {
  function init(options) {
    var opts;
    var canvas;
    var prodImage;

    initOpts(options);
    initCanvas();

    // set the image urls to the right options based on the type
    function initOpts(options) {
      opts = options;
      switch (opts.type) {
        case 'fb':
          _.merge(opts, {
            bgimg: opts.fbbgimg,
            bgImgScaleWidth: true,
            prodImgScaleHeight: true,
            tmImgOpts: { left: 8, top: 435, scaleX: .375, scaleY: .375 },
            logo: opts.fblogo,
            logoImgOpts: { left: 830, top: 435, scaleX: .77, scaleY: .77 },
            dataUrlMultiplier: 1.3
          });
          break;
        case 'pin':
          _.merge(opts, {
            bgimg: opts.pinbgimg == null ? null : opts.pinbgimg + '&w=1200&h=1200&fit=crop',
            prodImgScaleHeight: true,
            tmImgOpts: { left: 8, top: 455, scaleX: .20, scaleY: .20 },
            logo: opts.pinlogo,
            logoImgOpts: { left: 293, top: 455, scaleX: .45, scaleY: .45 },
            dataUrlMultiplier: 2
          });
          break;
        case 'multi':
          _.merge(opts, {
            bgimg: opts.multibgimg == null ? null : opts.multibgimg + '&w=600&h=1200&fit=crop',
            prodImgTopOffset: 0,
            prodImgScaleWidth: true,
            tmImgOpts: { left: 8, top: 493, scaleX: .25, scaleY: .25 },
            logo: opts.multilogo,
            logoImgOpts: { left: 494, top: 495, scaleX: .5, scaleY: .5 },
            dataUrlMultiplier: 2.16
          });
          break;
        case 'story':
          _.merge(opts, {
            bgimg: opts.multibgimg == null ? null : opts.multibgimg + '&w=1080&h=1920&fit=crop',
            // prodImgTopOffset: 0,
            prodImgScaleHeight: true,
            tmImgOpts: { left: 8, top: 758, scaleX: .25, scaleY: .25 },
            logo: opts.storylogo,
            logoImgOpts: { left: 426, top: 760, scaleX: .5, scaleY: .5 },
            dataUrlMultiplier: 2.5
          });
          break;
        case 'folded':
            _.merge(opts, {
              bgimg: null,
              prodImgScaleHeight: true,
              tmImgOpts: null,
              logoImgOpts: null,
              dataUrlMultiplier: 3
            });
          break;
        case 'col':
          _.merge(opts, {
            bgimg: 'white',
            prodImgScaleHeight: true,
            tmImgOpts: null,
            logoImgOpts: null,
            dataUrlMultiplier: 1
          });
      }
    }

    function initCanvas() {
      var bgCanImg;
      var prdCanImg;
      var tmCanImg;

      function loadImages() {
        var imageLoadingCount = 1;
    
        function imageLoaded() {
            imageLoadingCount -= 1;
            if (imageLoadingCount <= 0) {
                genCanV();
            } else {
                console.log('still waiting for ' + imageLoadingCount + ' images...');
            }
        }
    
        function imageError() {
            console.log('CORS request failed, retrying without crossorigin for', this.src);
            // remove this onerror listener to avoid an infinite loop
            this.onerror = null;
            // if CORS request fails, try without
            this.removeAttribute('crossorigin');
            this.src = this.src;
        }
    
        function updateURL(url) {
            if (url.includes('https://80steess3.imgix.net/Logos/')) {
                return url.replace('https://80steess3.imgix.net/Logos/', 'https://s3.amazonaws.com/80stees-images/Logos/');
            }
            return url;
        }
    
        prdCanImg = new Image();
        prdCanImg.crossOrigin = "anonymous";
        prdCanImg.onload = imageLoaded;
        prdCanImg.onerror = imageError;
        prdCanImg.src = updateURL(opts.masterimg);
        console.log('Product image source:', prdCanImg.src);
    
        if (opts.bgimg && opts.bgimg != 'white') {
            imageLoadingCount += 1;
            bgCanImg = new Image();
            bgCanImg.crossOrigin = "anonymous";
            bgCanImg.onload = imageLoaded;
            bgCanImg.onerror = imageError;
            bgCanImg.src = updateURL(opts.bgimg);
            console.log('Background image source:', bgCanImg.src);
        } else if (opts.bgimg == 'white') {
            bgCanImg = 'white';
        }
    
        if (opts.tmimg && opts.tmImgOpts) {
            imageLoadingCount += 1;
            tmCanImg = new Image();
            tmCanImg.crossOrigin = "anonymous";
            tmCanImg.onload = imageLoaded;
            tmCanImg.onerror = imageError;
            tmCanImg.src = updateURL(opts.tmimg);
            console.log('TM image source:', tmCanImg.src);
        }
    }
    

    
    

      function initBgFabricImage(canvas) {
        if (bgCanImg == null){
          canvas.setBackgroundColor(null)
          return
        }else if(bgCanImg == 'white'){
          canvas.setBackgroundColor('#ffffff')
          return
        }

        var imgBGInstance = new fabric.Image(bgCanImg, {
          selectable: false
        });

        if (opts.bgImgScaleWidth) {
          imgBGInstance.scaleToWidth(canvas.getWidth());
        }
        canvas.add(imgBGInstance);
      }

      function initPrdFabricImage(canvas) {
        var center = canvas.getCenter();
        var top, left, originX, originY;

        top = center.top;
        if (opts.prodImgTopOffset) {
          top = top + opts.prodImgTopOffset;
        }

        left = center.left;
        originX = 'center';
        originY = 'center';
        prodImage = new fabric.Image(prdCanImg, {
          lockUniScaling: true,
          centeredScaling: true,
          top: top,
          left: left,
          originX: originX,
          originY: originY
        });
        prodImage.setShadow({
          blur: 13,
          color: 'rgba(0,0,0,0.65)',
          offsetX: 2,
          offsetY: 4
        });

        if(opts.canvasId == 'folded-canvas'){
          prodImage.scaleToHeight(canvas.getHeight()-32);
          prodImage.scaleToWidth(canvas.getWidth()-32);
        }else{
          if (opts.prodImgScaleHeight) {
            prodImage.scaleToHeight(canvas.getHeight());
          }
          if (opts.prodImgScaleWidth) {
            prodImage.scaleToWidth(canvas.getWidth());
          }
        }
        canvas.add(prodImage);
      }

      function initTmFabricImage(canvas) {
        if (tmCanImg == null) return;
        var imgTMInstance = new fabric.Image(tmCanImg, {
          lockUniScaling: true,
          scaleX: opts.tmImgOpts.scaleX,
          scaleY: opts.tmImgOpts.scaleY,
          left: opts.tmImgOpts.left,
          top: opts.tmImgOpts.top,
          originX: 'left',
          originY: 'bottom'
        });
        if(opts.tmshadow == 'white') {
          imgTMInstance.setShadow({
            blur: 20,
            color: 'rgba(255,255,255,1)',
            offsetX: 4,
            offsetY: 4
          });
        } else {
          imgTMInstance.setShadow({
            blur: 6,
            color: 'rgba(0,0,0,0.35)',
            offsetX: 2,
            offsetY: 2
          });
        }
        canvas.add(imgTMInstance);
      }

      function initLogoFabricImage(canvas) {

        console.log('Canvas width:', canvas.width);
        if (opts.logoImgOpts == null) return;
    
        // Select the appropriate logo element
        var imgETElement = opts.logo === 'blue' ? document.getElementById('etLogoBlue') : document.getElementById('etLogoWhite');
    
        // Function to update the URL if it matches the imgix pattern
        function updateURL(url) {
            if (url.includes('https://80steess3.imgix.net/Logos/')) {
                return url.replace('https://80steess3.imgix.net/Logos/', 'https://s3.amazonaws.com/80stees-images/Logos/');
            }
            return url;
        }
    
        // Update the src attribute if necessary
        imgETElement.src = updateURL(imgETElement.src);
    
        // Wait for the image to load before adding to the canvas
        imgETElement.onload = function() {
            // Calculate scale based on canvas width
            var scaleFactor = (canvas.width * 0.25) / imgETElement.naturalWidth;
    
            // Create Fabric.js image instance
            var imgETInstance = new fabric.Image(imgETElement, {
                lockUniScaling: true,
                centeredScaling: true,
                scaleX: scaleFactor,
                scaleY: scaleFactor,
                left: canvas.width - (imgETElement.naturalWidth * scaleFactor) - 10,
                top: canvas.height - (imgETElement.naturalHeight * scaleFactor) - 10,
                originX: 'left',
                originY: 'top'
            });
    
            // Set shadow for the image
            imgETInstance.setShadow({
                blur: 6,
                color: 'rgba(0,0,0,0.35)',
                offsetX: 2,
                offsetY: 2
            });
    
            // Add the image instance to the canvas
            canvas.add(imgETInstance);
        };
    }
    
    

      function genCanV() {
        if(genCanV.called) {
          console.log('already called');
          return;
        }
        $('#' + opts.canvasId).removeClass('is--hidden');
        genCanV.called = true;

        canvas = new fabric.Canvas(opts.canvasId, { preserveObjectStacking: true, enableRetinaScaling: true, backgroundColor: 'white' });

        initBgFabricImage(canvas);
        initPrdFabricImage(canvas);
        initTmFabricImage(canvas);
        initLogoFabricImage(canvas);

        // init slider
        let scale = prodImage.scaleX * 100;
        if(opts.canvasId != "folded-canvas"){
          $("#" + opts.type + "-canvas-container").append(
            '<input type="range" data-action="resize-image" data-type="' + opts.type + '" value="' + scale + '">'
          );
          $("#" + opts.type + "-canvas-container input[data-action=resize-image]").on("input", function (e) {
            scaleProdImage($(this).val() / 100);
            });
        }
        function onObjectSelected(e) {
          let foldbuttons = document.getElementById('fold-zoom-buttons')
          let selectedImg = e.target.canvas.contextContainer.canvas.id
          if(selectedImg == 'folded-canvas'){
            foldbuttons.classList.remove('hidden')
            let zi = document.getElementById('increase-size')
            let zo = document.getElementById('decrease-size') 
            let elmnt = e.target._element
            zi.addEventListener('click',function(){
              zoomSrc(elmnt, '+')
            });
            zo.addEventListener('click',function(){
              zoomSrc(elmnt, '-')
            });

            function zoomSrc(elm,zoom){
                  let activeObject = canvas.getActiveObject();
                  let src = activeObject.getSrc()
                  var rg = /&fp-z=(\d*\.)?\d+/
                  var m = src.match(rg)[0];
                  var zoomlvl = m.split('=')[1];
                  if(zoom == '+'){
                    var newzoom = parseFloat(zoomlvl)+.03;
                  }else{
                    var newzoom = parseFloat(zoomlvl)-.03;
                  }
                  let newsrc = src.replace(m,'&fp-z='+newzoom);
                  //add new image back into canvas and declare cross origin
                  let zoomImg = new Image();
                  zoomImg.crossOrigin = "anonymous";
                  zoomImg.src = newsrc;

                  var center = canvas.getCenter();
                  var top, left, originX, originY;
                  top = center.top;
                  left = center.left;
                  originX = 'center';
                  originY = 'center';
                  zoomImg.onload = function () {    
                    var nimg = new fabric.Image(zoomImg, {
                        lockUniScaling: true,
                        centeredScaling: true,
                        top: top,
                        left: left,
                        originX: originX,
                        originY: originY
                    });
                    nimg.setShadow({
                      blur: 13,
                      color: 'rgba(0,0,0,0.65)',
                      offsetX: 2,
                      offsetY: 4
                    });
                    nimg.scaleToHeight(canvas.getHeight()-32);
                    nimg.scaleToWidth(canvas.getWidth()-32);
                    //clear canvas
                    canvas.clear();
                    canvas.add(nimg);
                    let objs = canvas.getObjects();
                    canvas.setActiveObject(objs[0]);
                };
              }
          }      
        }
        canvas.on('object:selected', onObjectSelected);
      }

      loadImages();
    }

    function getCanvas() {
      return canvas;
    }

    function getJpegBlob() {
      var dataURL = canvas.toDataURL({ format: 'jpeg', quality: 1, multiplier: opts.dataUrlMultiplier });

      // Decode the dataURL
      var binary = atob(dataURL.split(',')[1]);
      // Create 8-bit unsigned array
      var array = [];
      for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      // Return our Blob object
      return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    }

    function getPngBlob() {
      var dataURL = canvas.toDataURL({ format: 'png', quality: 1, multiplier: opts.dataUrlMultiplier });

      // Decode the dataURL
      var binary = atob(dataURL.split(',')[1]);
      // Create 8-bit unsigned array
      var array = [];
      for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      // Return our Blob object
      return new Blob([new Uint8Array(array)], {type: 'image/png'});
    }

    function scaleProdImage(percent) {
      prodImage.set({
        scaleY: percent,
        scaleX: percent,
      });
      canvas.renderAll();
    }

    function getProdImage() {
      return prodImage;
    }

    return {
      getCanvas: getCanvas,
      getJpegBlob: getJpegBlob,
      getPngBlob: getPngBlob,
      scaleProdImage: scaleProdImage,
      getProdImage: getProdImage,
    }
  }

  return {
    init: init
  }
})();
