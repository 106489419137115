App.Supplier = (function () {

  function initNew() {
    $("select[data-action=select-dropship-type]").on("change", function(e) {
      var disabled = $(this).val() != 'csv';
      $("[data-group=dropship-type-csv]").prop("disabled", disabled);
    });
  }

  function initEdit() {
    initNew();
  }

  return {
    initNew: initNew,
    initEdit: initEdit,
  }
})();

