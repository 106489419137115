App.PurchaseOrder = (function () {
  function initShow() {
    initActions();
  }

  function initEdit() {
    preventSendOnChanged();
    enableUpdateButtons();
    initSubmitPOItemsOnChangeHandler();
  }

  function initActions() {
    $(document).on('click', '[data-action="void-po-dialog"]', function(e) {
      e.preventDefault();
      openForm($(this));
    });
  }

  function preventSendOnChanged() {
    // Disable Send to Supplier button until changes persisted
    $('input[type=text]').on('keypress paste', function() {
      $('.finalize_po_button').attr('disabled', 'disabled');
      $('.finalize_po_button').off('click').on('click', function(e){
        alert('You must Update or Refresh the page before sending to supplier')});
      $('#apply_price_breaks').attr('disabled', 'disabled');
      $('#apply_price_breaks').off('click').on('click', function(e){
        alert('You must Update or Refresh the page before sending to supplier')});
    });
  }

  function enableUpdateButtons() {
    $('.update_purchase_order').click(function(){
      $('#update_purchase_order_form').submit();
    });
  }

  function initSubmitPOItemsOnChangeHandler() {
    $('input.purchase_order_item_textbox').on('change', function(e) {
      postPurchaseOrderItem($(this));
    });
  }

  function postPurchaseOrderItem(el) {
    data = {
      id: el.data('id'),
      item_code: el.data('item-code'),
      purchase_order_id: el.data('purchase-order-id')
    }
    data[el.attr('name')] = el.val();

    $.ajax({
      type: el.data('method'),
      url: el.data('url'),
      data: data,
      success: function(data) {
        App.Alert.show(data.message, 'notice');
      },
      error: function(data) {
        App.Alert.show(data.message, 'alert');
        var el = $('#ajax_response');
        el.html('<div class="' + data.responseJSON.status + '">' + data.responseJSON.message + '</div>');
        el.show().stop().css({ opacity: 1.0 });
      },
      dataType: 'json'
    });
  }

  function openForm(el) {
    var width = el.data("width") || "650px";
    var url = el.data("url");
    $.get(url, function(data) {
      vex.open({
        unsafeContent: data,
        contentCSS: {width: width},
        overlayClosesOnClick: false
      });
    });
  }

  return {
    initShow: initShow,
    initEdit: initEdit,
    initSubmitPOItemsOnChangeHandler: initSubmitPOItemsOnChangeHandler
  }
})();
