App.SupplierPurchaseOrder = (function () {

  function initAcknowledgeReceipt() {
    App.Form.pikaday('.pikaday');
  }

  return {
    initAcknowledgeReceipt: initAcknowledgeReceipt
  }
})();

