window.App.Notification = (function() {
    function init() {

    const notes = document.querySelectorAll('.speech-bubble');

    for(n of notes){
      n.addEventListener('click',function(e){
       let currentNote = e.target.closest('.notes-wrapper');
       currentNote.querySelector('.notes-wrap').classList.toggle('hide');
      });
    }

    var filterSelects = document.querySelectorAll('#notifications_filter select');
    for(var i=0;i<filterSelects.length;i++){
    filterSelects[i].addEventListener('change',function(e){
        document.getElementById('notifications_filter').submit();
    });
    }

    }
    return {
        init: init
    }

})();