import select2 from 'select2';

App.Item = (function () {
  function initShow() {
    add_new_div_element();

    $("#supplier_list").select2({
      tags: true,
      placeholder: 'Enter or select tracking #'
    });

    $(".review_changes_btn").prop('disabled', true);
  }

  function add_new_div_element() {
    $(".repeat").on('click', function () {
      var $self = $(this);
      if ($self.prev().find('select')[0].value != '') {
        $self.before($self.prev().clone());
        $self.prev().find('p').css({"display": "block"});
        $self.prev().find('a').css("display", "block")

        var select_length = $self.prev().find('select').length;
        for (var i = 0; i < select_length; i++) {
          var old_name = $self.prev().find('select')[i].name;
          var arr = old_name.split('_');
          var id_value = arr[arr.length-1];
          var new_id_value = String(parseInt(id_value) + 1);
          arr[arr.length-1] = new_id_value;
          var new_name = arr.join('_');
          $self.prev().find('select')[i].name = new_name;
          $self.prev().find('select')[i].id = new_name;

          let new_prd_value = $('#' + $self.prev().find('select')[i].id + '');
          if (i == 0) {
            new_prd_value.find("option:selected").prop("selected", false);
          } else if (i == 1) {
            new_prd_value.empty(); // remove old options
            new_prd_value.append($("<option></option>").attr("value", '- Not Set -').text('- Not Set -'));
          }
          $(".review_changes_btn").prop('disabled', true);
          // $self.remove();
        }
        return false;
      } else {
        alert( 'Please select the last Match On dropdown to proceed');
        return false;
      }
    });

    $(".table_row_repeat").on("click",function(){
      var $tableBody = $('#tbl').find("tbody");
      var $trLast = $tableBody.find("tr:last");
      if ($trLast.find('td:first').find('input')[0].value == '') {
        alert( 'Please enter the last sub-item size to add new row. If the size is not mentioned, changes will update to all the matched sub-items.');
        return false;
      } else {
        var $trNew = $trLast.clone();
        $trNew.find('.delete_row').css('display', 'block');
        $trLast.after($trNew);

        var select_length = $trNew.find('input').length;
        for (var i = 0; i < select_length; i++) {
          let old_name = $trNew.find('input')[i].name;
          let old_name_1 = old_name.split('[')[0];
          let arr = old_name_1.split('_');
          let id_value = arr[arr.length-1];
          let new_id_value = String(parseInt(id_value) + 1);
          arr[arr.length-1] = new_id_value;
          let new_name = arr.join('_');
          let new_name_1 = new_name + '[' + old_name.split('[')[1];
          $trNew.find('input')[i].name = new_name_1;
          if ($trNew.find('input')[i].type == 'text') {
            $trNew.find('input')[i].value = '';
          }
          if (($trNew.find('input')[i].type == 'radio') && ($trNew.find('input')[i].checked)) {
            $trLast.find('input')[i].checked = true;
          }
          if (i == 12) {
            $trNew.find('input')[12].checked = true;
          } else if (i == 15) {
            $trNew.find('input')[15].checked = true;
          }
        }
        return false;
      }
    });

    $("#supplier_list").on('change', function(){

      var tableBody = $('#tbl').find("tbody");
      var trLast = tableBody.find("tr:last");

      var si_prop_present = false;
      var match_on_value = ($(this).closest('.indent_content').find('#wrap .repeatable').last().find('select')[0].value != '');
      var select_length = trLast.find('input').length;
      for (var i = 0; i < select_length; i++) {
        if ((trLast.find('input')[i].type == 'text') && (trLast.find('input')[i].value != '')) {
          si_prop_present = true
        }
      }

      if ((this.value != '') && match_on_value && si_prop_present) {
        $(".review_changes_btn").prop('disabled', false);
      } else {
        $(".review_changes_btn").prop('disabled', true);
      }
    });

  }

  return {
    initShow: initShow
  }
})();
