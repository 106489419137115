import select2 from 'select2';

App.ProductFeedInfo = (function () {
  function initShow() {
    $("#select_breadcrumb").select2();
  }

  return {
    initShow: initShow
  }
})();
