App.OrderReturnItems = (function () {
  function init() {
    initActions();
  }

  function initActions() {
    // $(document).on("change", "[data-action=statusChange]", function() {
    //   statusChange($(this));
    // });
    $(document).on("click", ".restock_cart_class", function(){
      var restock_templates = $(this).parent().parents().eq(2).find('[id^="restock_cart_"]')
      var restock_checked = false;

      for(var i = 0; i < restock_templates.length; i++){
        if (restock_templates[i].checked) {
          restock_checked = true;
        }
      }

      if (restock_checked) {
        $("#return_cart_name").val($("#recently_used_cart_name").val());
        $("#return_cart_location").val($("#recently_used_cart_location").val());
      } else {
        $("#return_cart_name").val('');
        $("#return_cart_location").val('');
      }
    });
  }

  return {
    init: init
  }
})();
