const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import '../stylesheets/init';

import Rails from 'rails-ujs';
Rails.start();

import moment from 'moment'

window.$ = $
window._ = _;

function importAll (r) {
  r.keys().forEach(r);
}
import '../javascripts/init.js'
import '../javascripts/app/collection_app.js'
importAll(require.context('../javascripts/app/', true, /\.js$/))

import vex from 'vex-js'
import vexDialog from 'vex-dialog'
vex.registerPlugin(vexDialog)
vex.defaultOptions.className = 'vex-theme-default'

window.vex = vex
