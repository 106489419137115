App.TrackingNumber = (function () {
  function initShow() {
    $(document).on("click", "[data-action=line_tracking_number]", function (e) {
      e.preventDefault();
      var formUrl = $(this).data('url');
      openForm(formUrl);
    });
  }

  function openForm(url) {
    $.get(url, function (data) {
      vex.open({
        unsafeContent: data
      });

      $('.tracking_selector select').select2({
        placeholder: 'Enter or select tracking #',
        dropdownParent: $('.vex-content'),
        tags: true
      });
    });
  }

  return {
    initShow: initShow
  }
})();
