App.DropShip = (function () {
  function initShow() {
    $(document).on("click", "[data-action=new_dropshipping_note]", function (e) {
      e.preventDefault();
      url = $(this).data('url');
      openForm(url);
    })

    $(document).on("click", "[data-action=new_edit_dso_note_dropship_order]", function (e) {
      e.preventDefault();
      url = $(this).data('url');
      openForm(url);
    })
  }

  function openForm(url) {
    $.get(url, function (data) {
      var form = $(data);
      vex.open({
        unsafeContent: data
      });
    });
  }

  return {
    initShow: initShow
  }
})();
