import select2 from 'select2';

App.OrderEditItems = (function () {
  function init() {
    initShipment();
    initActions();
  }

  function initActions() {
    $(document).on("click", "[data-action=addLineItem]", function() {
      addLineItem();
    });
    $(document).on("click", "[data-action=removeLineItem]", function() {
      removeLineItem($(this));
    });
    $(document).on("click", "#fetch_line_item_size_call", function(event){
      var li_id = $(this).parent().find('[id^="current_li_id"]')[0].value

      var nil_val_present = ($("#si_item_code_value_"+li_id).val() == '') || ($("#exchange_reason_"+li_id).val() == '');

      var exchange_restock = $(this).parent().find('.exchange_restock_class')[0].checked;
      if (nil_val_present || exchange_restock) {
        if ($("#si_item_code_value_"+li_id).val() == '') {
          alert("Please select the item");
          return false;
        } else if ($("#exchange_reason_"+li_id).val() == '') {
          alert("Please select the return reason");
          return false;
        }
        if (exchange_restock) {
          if ($("#cart_name_val_"+li_id).val() == '') {
            alert("Please enter the cart name");
            return false;
          } else if ($("#cart_location_val_"+li_id).val() == '') {
            alert("Please enter the location");
            return false;
          } else {
            var current_li_values = getCurrentLiValues(this);
            addLiValues(current_li_values[0], current_li_values[1], current_li_values[2], current_li_values[3], current_li_values[4], this)
            appendValues(this);
          }
        } else {
          return false;
        }
      } else {
        var current_li_values = getCurrentLiValues(this);
        addLiValues(current_li_values[0], current_li_values[1], current_li_values[2], current_li_values[3], current_li_values[4], this)
        appendValues(this);
      }
    });
    $(document).on("click", ".exchange_restock_class", function(event){
      var li_id = $(this.id.split('_')).get(-1);
      var cart_name = $("#recently_used_cart_name").val();
      var cart_location = $("#recently_used_cart_location").val();
      if (this.checked) {
        $(this).parent().parent().find('.show_fieldset').css('display', 'block');
        $(this).parent().next().find('#cart_name_val_'+li_id).val(cart_name);
        $(this).parent().next().find('#cart_location_val_'+li_id).val(cart_location);
      } else {
        $(this).parent().parent().find('.show_fieldset').css('display', 'none');
        $(this).parent().next().find('#cart_name_val_'+li_id).val('');
        $(this).parent().next().find('#cart_location_val_'+li_id).val('');
      }
    });
  }

  function getCurrentLiValues(event) {
    var li_id_val = $(event).parent().find('[id^="current_li_item_code"]')[0].value;
    var li_item_code, li_quatity, li_price, li_line_tax, li_line_discount = '';
    var total_inputs = $("table.order-line-items tbody").find('[id^='+li_id_val+'-]');
    for(var i = 0; i < total_inputs.length; i++){
      var split_vals = total_inputs[i].id.split('-');
      var id_val = split_vals[split_vals.length - 1];
      switch (id_val) {
        case 'item_code':
          li_item_code = total_inputs[i].value
          break;
        case 'quantity':
          li_quatity = total_inputs[i].value
          break;
        case 'price':
          li_price = total_inputs[i].value
          break;
        case 'line_tax':
          li_line_tax = total_inputs[i].value
          break;
        case 'line_discount':
          li_line_discount = total_inputs[i].value
          break;
      }
    }
    return [li_item_code, li_quatity, li_price, li_line_tax, li_line_discount]
  }

  function addLiValues(li_item_code, li_quatity, li_price, li_line_tax, li_line_discount, event) {
    var li_id = $(event).parent().find('[id^="current_li_id"]')[0].value
    var selected = $("#si_item_code_value_"+li_id).val();
    var line_items_hash= $("#line_items_hash_"+li_id).val();
    var sub_item_hash= $("#sub_item_hash_"+li_id).val();
    var li_val = $(event).parent().find('[id^="current_li_item_code"]')[0].value;
    var parsed_val = JSON.parse(line_items_hash);
    var parsed_si_hash = JSON.parse(sub_item_hash);
    var current_unit_price = $("#"+li_val+"-price").val();
    var current_line_price = parseFloat(parsed_val[li_val]['line_price'].split('$')[1]);
    var current_line_discount = parseFloat(parsed_val[li_val]['line_discount'].split('$')[1]);
    var current_percentage = parseFloat(((current_line_discount/current_line_price)*100).toFixed(3))

    var template = $($("#line-item-template").html());
    var new_templates = template.find('[id^="new-edit_items-"]')
    for(var i = 0; i < new_templates.length; i++){
      var split_vals = new_templates[i].id.split('-');
      var id_val = split_vals[split_vals.length - 1];
      switch (id_val) {
        case 'item_code':
          new_templates[i].value = selected
          break;
        case 'quantity':
          new_templates[i].value = li_quatity
          break;
        case 'price':
          new_templates[i].value = parsed_si_hash[selected]['unit_price']
          break;
        case 'line_tax':
          new_templates[i].value = parsed_val[li_val]['line_tax']
          break;
        case 'line_discount':
          new_templates[i].value = ((parsed_si_hash[selected]['unit_price'] * current_percentage) / 100).toFixed(2)
          break;
      }
    }
    $("table.order-line-items tbody").append(template);
    initShipment();
    adjustLineNumbers();
  }

  function appendValues(event) {
    // Get line_item id and reason value
    var li_id = $(event).parent().find('[id^="current_li_id"]')[0].value
    $("#fetch_item_size_modal_"+li_id).css('display', 'none');
    var reason = $("#exchange_reason_"+li_id).val();
    var cart_name = $("#cart_name_val_"+li_id).val();
    var cart_location = $("#cart_location_val_"+li_id).val();
    var exchange_restock = $(event).parent().find('.exchange_restock_class')[0].checked;

    $("#fetch_line_item_size_"+li_id).css('display', 'none'); // hide "Exchange item for size" button
    $("#return_item_"+li_id).css('display', 'block'); // Show the selected reason instead Exchange item button.
    $("#reason_return_item_"+li_id).val(reason);
    if (exchange_restock) {
      $("#exchange_cart_name_"+li_id).val(cart_name);
      $("#exchange_cart_location_"+li_id).val(cart_location);
      $("#exchange_restock_"+li_id).prop('checked', exchange_restock);
    }
    else {
      $("#exchange_cart_name_"+li_id).hide();
      $("#exchange_cart_name_"+li_id+"_label").hide();
      $("#exchange_cart_location_"+li_id).hide();
      $("#exchange_cart_location_"+li_id+"_label").hide();
      $("#exchange_restock_"+li_id).hide();
      $("#exchange_restock_"+li_id+"_label").hide();
    }
  }

  function addLineItem() {
    var template = $($("#line-item-template").html());
    $("table.order-line-items tbody").append(template);
    initShipment();
    adjustLineNumbers();
  }

  function initShipment() {
    $(".tracking_select").select2({
      tags: true,
      placeholder: 'Enter or select tracking #'
    });
  }

  function removeLineItem(el) {
    el.closest("tr").remove();
    adjustLineNumbers();
  }

  function adjustLineNumbers() {
    $("table.order-line-items tr").each(function(index, el) {
      $(el).find("td:first").text( index );
    });
  }

  return {
    init: init
  }
})();
