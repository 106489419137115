App = {}

$(function() {
  App.Form.preventDoubleSubmit();
  App.Dropdown.init();
});



document.addEventListener('DOMContentLoaded', function() {
  const themeToggle = document.getElementById('theme-toggle');
  let darkThemeStyleElement = null;

  // Function to create the style element with dark theme CSS
  function enableDarkTheme() {
      darkThemeStyleElement = document.createElement('style');
      darkThemeStyleElement.type = 'text/css';
      darkThemeStyleElement.textContent = getDarkThemeCSS();
      document.head.appendChild(darkThemeStyleElement);
      themeToggle.querySelector('i').className = 'fas fa-sun';
      localStorage.setItem('theme', 'dark');  // Save theme preference to local storage
  }

  // Function to remove the style element
  function disableDarkTheme() {
      if (darkThemeStyleElement) {
          document.head.removeChild(darkThemeStyleElement);
          darkThemeStyleElement = null;
      }
      themeToggle.querySelector('i').className = 'fas fa-moon';
      localStorage.setItem('theme', 'light');  // Save theme preference to local storage
  }

  // Check local storage for theme preference
  const savedTheme = localStorage.getItem('theme');
  if (savedTheme === 'dark') {
      enableDarkTheme();
  }

  themeToggle.addEventListener('click', function() {
      // Check if the dark theme style tag already exists
      if (darkThemeStyleElement) {
          disableDarkTheme();
      } else {
          enableDarkTheme();
      }
  });
});

function getDarkThemeCSS() {
  return `
      #page-body{background:#282A36;color:#dadada;}
      body{background:#282A36;}
      #page-body dl, #page-body h1, #page-body h2, #page-body h3, #page-body h4, #page-body h5, #page-body h6, #page-body li, #page-body p{color:#afafaf}
      table{background:#2e2e35;}
      table tr.alt, table tr.even, table tr:nth-of-type(2n),table.light-striped tr:nth-of-type(2n) {
          background: #25252b;
      }
      a{color:#d55ea2;transition:all 0.4s;}
      a:hover{color:#B02A55;}
      .breadcrumbs{background-color:#948ca3;}
      label{color:#aba2a2}
      table tr td, table tr th{color:#F8F8F2}
      table thead{background:#3e3741}
      table thead tr td, table thead tr th{color:#ccc}
      .breadcrumbs{border:none; color:#ccc; border-radius: 0;background:#16171b}
      .breadcrumbs>.current, .breadcrumbs>.current a{color:#4FFB7B}
      .footer{background:#221c28;}
      .top-bar-section li:not(.has-form) a:not(.button),.top-bar,.top-bar-section .has-form {background:#322532;}
      nav.top-bar{box-shadow: none;}
      fieldset legend{background:#171717;}
      .form-panel{background:#282828;border:1px solid #181818;}
      input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week], textarea, select,.select2-container--default .select2-selection--multiple,.select2-container--default .select2-selection--single{background:#333}
      input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=datetime]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus, select:focus{background:#141414; color:#eee;}
      input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week], textarea, select{color:#ccc;}
      select:hover{background:#333}
      .button.secondary, button.secondary{background-color: #3e2d41;}
      .button.secondary:hover, button.secondary:hover{background-color: #532e5a}
      body.main-c .main-button {background-color: #438baf;}
      tr.dark-row{background:#4c4c54 !important;}
      .line-item img, .line-item-image img{
          max-width: 120px;
          max-height: 150px;
          -webkit-filter: drop-shadow(3px 4px 3px #000);
          background: #ffffff3d;
          padding: 8px 4px;
          border-radius: 14px;
          box-shadow: 2px 2px 4px #222;
      }
      tr.also-ordered {
          background: #444 !important;
      }
      tr.also-ordered table tr{
              background: #2c2930 !important;
      }
      tr.also-ordered table tr:nth-child(even){
          background: #212121 !important;
      }
  `;
}