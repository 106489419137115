App.InactivityMonitor = (function () {
  var init = function(user_name) {
    setTimeout( function(){
      confirmCorrectUser(user_name);
    }, 180000 );
  };

  var confirmCorrectUser = function(user_name) {
    vex.dialog.buttons.YES.text = 'Yes'
    vex.dialog.buttons.NO.text = 'No'
    vex.dialog.confirm({
      message: "Are you " + user_name + "?",
      callback: function(value) {
        if (value) {
          init(user_name); // start clock ticking again
        }
        else {
          window.location = '/logout'
        }
      }
    });
  }

  return {
    init: init
  };
})();
