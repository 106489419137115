import select2 from 'select2';
import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;

App.ProductImage = (function () {
  var crops;
  var images;
  var masterImagePath;
  var masterDz;
  var extraDz;

  function initEdit() {
    crops = {};
    images = {
      extra: []
    }
    initDropzones();
    initSaveButton();
    initSocialSection();
    initCropLoadButton();
    $("select").select2();
  }

  function initDropzones() {
    masterDz = new Dropzone("#master-image", {
      sending: function(file, xhr) { $.rails.CSRFProtection(xhr); },
      thumbnailWidth: 225,
      thumbnailHeight: 225,
      thumbnailMethod: 'contain',
      dictDefaultMessage: "Drop the master transparent PNG here or click to upload.",
      previewTemplate: $("#dropzone-template").html(),
    });
    masterDz.on("success", function(file, data) {
      images.master = masterImagePath = data.path;
      // remove all but last image
      $("#master-image .dz-preview:not(:last)").each(function(index, e) {
        e.remove();
      });
      enableSaveButton();
    });

    masterImagePath = $("#master-image").data("image");
    if (masterImagePath != "") {
      addExistingImageToDropzone(masterDz, masterImagePath);
    }

    extraDz = new Dropzone("#extra-images", {
      sending: function(file, xhr) { $.rails.CSRFProtection(xhr); },
      thumbnailWidth: 225,
      thumbnailHeight: 225,
      thumbnailMethod: 'contain',
      dictDefaultMessage: "Drop the extra product images here or click to upload.",
      previewTemplate: $("#dropzone-template").html(),
      addRemoveLinks: true
    });
    extraDz.on("success", function(file, data) {
      images.extra.push(data.path);
      enableSaveButton();
    });
    extraDz.on("removedfile", function(file) {
      if (file.path) {
        // remove it if it was an existing image
        var index = images.extra.indexOf(file.path);
        if (index >= 0) {
          images.extra.splice(index, 1);
        }
      }
      enableSaveButton();
    });
    var extraImages = $("#extra-images").data("images");
    if (extraImages) {
      images.extra = extraImages;
      $.each(images.extra, function(index, path) {
        addExistingImageToDropzone(extraDz, path);
      });
    }

    var frontArtworkDz = new Dropzone("#front-artwork", {
      sending: function(file, xhr) { $.rails.CSRFProtection(xhr); },
      paramName: "front_artwork",
      thumbnailWidth: 225,
      thumbnailHeight: 225,
      thumbnailMethod: 'contain',
      dictDefaultMessage: "Drop the front artwork image here or click to upload.",
      previewTemplate: $("#dropzone-template").html(),
      addRemoveLinks: true
    });
    
    frontArtworkDz.on("success", function(file, data) {
      images.front_artwork = data.path;
      enableSaveButton();
    });

    frontArtworkDz.on("removedfile", function(file) {
      if (file.path) {
        // remove it if it was an existing image
        console.dir(file)
        console.dir(images)
        var index = images.front_artwork.indexOf(file.path);
        if (index >= 0) {
          images.front_artwork.splice(index, 1);
        }
      }
      enableSaveButton();
    });


    var designArtworkDz = new Dropzone("#design-artwork", {
      sending: function(file, xhr) { $.rails.CSRFProtection(xhr); },
      paramName: "design_artwork",
      thumbnailWidth: 225,
      thumbnailHeight: 225,
      thumbnailMethod: 'contain',
      dictDefaultMessage: "Drop the design artwork image here or click to upload.",
      previewTemplate: $("#dropzone-template").html(),
    });
  
    designArtworkDz.on("success", function(file, data) {
      images.design_artwork = data.path;
      console.log("Design artwork uploaded:", data.path);
      enableSaveButton();
    });
  
  
    var backArtworkDz = new Dropzone("#back-artwork", {
      sending: function(file, xhr) { $.rails.CSRFProtection(xhr); },
      paramName: "back_artwork",
      thumbnailWidth: 225,
      thumbnailHeight: 225,
      thumbnailMethod: 'contain',
      dictDefaultMessage: "Drop the back artwork image here or click to upload.",
      previewTemplate: $("#dropzone-template").html(),
    });
  
    backArtworkDz.on("success", function(file, data) {
      images.back_artwork = data.path;
      enableSaveButton();
    });
  
    // Load existing images if present
    var frontArtworkPath = $("#front-artwork").data("image");
    if (frontArtworkPath) {
      addExistingImageToDropzone(frontArtworkDz, frontArtworkPath);
    }
  
    var backArtworkPath = $("#back-artwork").data("image");
    if (backArtworkPath) {
      addExistingImageToDropzone(backArtworkDz, backArtworkPath);
    }
    
    var designArtworkPath = $("#design-artwork").data("image");
    if (designArtworkPath) {
      addExistingImageToDropzone(designArtworkDz, designArtworkPath);
    }
  }

  function addExistingImageToDropzone(dz, path) {
    if (path == null) return;
    // Use aws url to avoid tainted canvas issues
    var url = "https://s3.amazonaws.com/80stees-images/" + path;
    var name = url.split("/").pop();
    var mockFile = { name: name, size: 0, path: path };
    dz.emit("addedfile", mockFile);
    dz.emit("thumbnail", mockFile, url);
    dz.emit("complete", mockFile);
  }

  function initSocialSection() {
    $("#edit-social-button").on("click", function(e) {
      e.preventDefault();
      enableSocialSection();
    });

    if ($("#social-section").data("enablesocial")) {
      enableSocialSection();
    }
  }

  function enableSocialSection() {
    $("#edit-social-button").hide();
    $("#social-section").show();
    initBgImageSelect();
    initTmImageSelect();
  }

  function initCropLoadButton() {
    $("#load-button").on("click", function(e) {
      e.preventDefault();
      initCrops();
    });
  }

  function initCrops() {
    var opts = {}
    opts.masterimg = "https://s3.amazonaws.com/80stees-images/" + masterImagePath;
    var bgimg = $('select[name=bgimg]').val();
    if (bgimg) {
      var parts = bgimg.split("||");
      opts.fbbgimg = parts[0];
      opts.multibgimg = parts[1];
      opts.pinbgimg = opts.multibgimg;
      opts.storybgimg = opts.multibgimg;
    }

    opts.fblogo = $("input[name=fblogo]:checked").val();
    opts.multilogo = $("input[name=multilogo]:checked").val();
    opts.pinlogo = $("input[name=pinlogo]:checked").val();
    opts.storylogo = $("input[name=storylogo]:checked").val();

    var tmimgurl = $('#tmImgSelect').val();
    if (tmimgurl != "") {
      opts.tmimg = 'https://s3.amazonaws.com/80stees-images/Logos/' + tmimgurl + '?fit=fill&h=196&w=460&usm=15&bg=00354651&q=100';
    }
    opts.tmshadow = $("input[name=tmshadow]:checked").val();

    crops.col = initCrop({ type: 'col', width: 392, height: 600, opts: opts});
    crops.fb = initCrop({ type: 'fb', width: 840, height: 438.9, opts: opts});
    crops.multi = initCrop({ type: 'multi', width: 500, height: 500, opts: opts});
    crops.pin = initCrop({ type: 'pin', width: 300, height: 460, opts: opts});
    crops.story = initCrop({ type: 'story', width: 432, height: 768, opts: opts});
    crops.folded = initCrop({ type: 'folded', width: 392, height: 600, opts: opts});
    console.log('Crops initialized');

    enableSaveButton();
  }

  function enableSaveButton() {
    $("#save-button").prop("disabled", false);
    $("#save-folded-button").prop("disabled", false);
  }

  function initCrop(opts) {
    var container = $("#" + opts.type + "-canvas-container");
    container.html(
      '<canvas id="' + opts.type + '-canvas" width="' + opts.width + '" height="' + opts.height + '"></canvas>'
    );
    return App.ProductImageCrop.init(_.merge({canvasId: opts.type + '-canvas', type: opts.type}, opts.opts));
  }

  function initBgImageSelect() {
    var select = $('#bgImgSelect');
    $.get('https://sheets.googleapis.com/v4/spreadsheets/1WEB8tZ3N2QQmo1rGOVDIeNYdwWA-mP463xeLZ24LnTI/values/sheet1?key=AIzaSyAKW2SVf8xXEyqma-HR_GsxiLCAkoOpaFk', function(data) {
      $.each(data.values, function(index, e) {
        if (index > 0) { // skip header
          var desc = e[1];
          var url = e[0];
          var murl = e[5];
          var option = $('<option>', {
            value: url + '||' + murl,
            text : desc
          }).data('multiurl', murl);
          select.append(option);
        }
      });

      select.on("change", function() {
        var val = $(this).val() || "";
        if (val == "") {
          $("#fbbgImgPreview").html("");
          $("#multibgImgPreview").html("");
        } else {
          var parts = val.split("||");
          var url = parts[0];
          var multiurl = parts[1];
          $("#fbbgImgPreview").html("<img src=\"" + url + "&w=350&h=200&fit=crop&usm=18" + "\" />");
          $("#multibgImgPreview").html("<img src=\"" + multiurl + "&w=350&h=200&fit=crop&usm=18" + "\" />");
        }
      });
    });
  }

  function initTmImageSelect() {
    var select = $('#tmImgSelect');
    var tm = select.data("tm");
    $.get('https://sheets.googleapis.com/v4/spreadsheets/1t922UerK-2XyEGzsKsoYCD7FSuqHvN2KQWaOVK0EyZg/values/Sheet1?key=AIzaSyAKW2SVf8xXEyqma-HR_GsxiLCAkoOpaFk', function(data) {
      $.each(data.values, function(index, e) {
        if (index > 0) { // skip header
          var title = e[0];
          var image = 'tm-'+title.replace(/ /g,'-').toLowerCase()+'.png';
          select.append($('<option>', {
            value: image,
            text: title,
            selected: title == tm
          }));
        }
      });
    });
  }

  function initSaveButton() {
    $("#save-button").on("click", function(e) {
      e.preventDefault();
      var button = $(this);
      button.text("Uploading images...");
      button.prop('disabled', true);
      var uploadUrl = button.data('uploadurl');
      var saveUrl = button.data('saveurl');
      var completeUrl = button.data('completeurl');
      var sku = button.data('sku');
      var promises = [];
      var remaining = 0;
      _.forOwn(crops, function(crop, type) {
        var fd = new FormData();
        if(type == 'folded'){
          fd.append("blob", crop.getPngBlob());
          fd.append("file_name", sku + '.' + type + '.png');
        }else{
          fd.append("blob", crop.getJpegBlob());
          fd.append("file_name", sku + '.' + type + '.jpeg');
        }
        var promise = $.ajax({
          url: uploadUrl,
          type: 'POST',
          data: fd,
          processData: false,
          contentType: false,
        }).done(function(data) {
          images[type] = data.path;
          remaining -= 1;
          button.text("Uploading images - " + remaining + " remaining...");
        });
        promises.push(promise);
        remaining += 1;
      });

      button.text("Saving... " + remaining + " remaining");

      // wait for all crop uploads
      Promise.all(promises).then(function () {
        button.text("Saving...");

        // now save
        $.ajax({
          url: saveUrl,
          type: 'POST',
          data: {
            images: images
          },
        }).done(function(data) {
          App.Alert.show("Images saved successfully.  Redirecting...", 'notice');
          document.location = completeUrl;
        }).fail(function(xhr) {
          if (xhr.status == 422) {
            var data = $.parseJSON(xhr.responseText);
            App.Alert.show("Error saving images: " + data.errors.join(', '), 'alert')
          } else {
            App.Alert.show("An unknown error occurred - status: " + xhr.status, 'alert');
          }
        });
      });
    });


    $("#save-folded-button").on("click",function(e){
      e.preventDefault();
      var button = $(this);
      button.text("Uploading folded image");
      button.prop('disabled', true);
      var uploadUrl = button.data('uploadurl');
      var saveUrl = button.data('saveurl');
      var uploadType = button.data('uploadtype');
      var completeUrl = button.data('completeurl');
      var sku = button.data('sku');
      _.forOwn(crops, function(crop, type) {
        if(type == 'folded'){
          var fd = new FormData();
          fd.append("blob", crop.getPngBlob());
          fd.append("file_name", sku + '.' + type + '.png');
          $.ajax({
            url: uploadUrl,
            type: 'POST',
            data: fd,
            processData: false,
            contentType: false,
          }).done(function(data){
            images[type] = data.path;
            nowSave();
          })
         }
        })

        function nowSave(){
          $.ajax({
            url: saveUrl+'?upload_type='+uploadType,
            type: 'POST',
            data: {
              images: images
            },
          }).done(function(data) {
            App.Alert.show("Folded Image saved successfully.  Redirecting...", 'notice');
            document.location = completeUrl;
          }).fail(function(xhr) {
            if (xhr.status == 422) {
              var data = $.parseJSON(xhr.responseText);
              App.Alert.show("Error saving folded image: " + data.errors.join(', '), 'alert')
            } else {
              App.Alert.show("An unknown error occurred - status: " + xhr.status, 'alert');
            }
          });
        }

    });




  }

  function debug() {
    return {
      images: images
    }
  }

  return {
    initEdit: initEdit,
    debug: debug
  }
})();
