App.OrderNote = (function () {
  function init() {
    // enable charge / refund form
    $(document).on("click", "[data-action=new_order_note]", function(e) {
      e.preventDefault();
      url = $(this).data('url');
      openForm(url);
    })

    $(document).on("click", "[data-action=edit_order_note]", function (e) {
      e.preventDefault();
      url = $(this).data('url');
      openForm(url);
    })
  }

  function openForm(url) {
    $.get(url, function(data) {
      var form = $(data);
      vex.open({
        unsafeContent: data
      });
    }).then(function(){
      let curUrl = window.location.href;
      if(curUrl.includes('/shipments/')){
        //set "is this shipping note = true"
        document.querySelector('.vex-content input[type=checkbox]').checked = true
      }
    });
  }

  return {
    init: init
  }
})();

