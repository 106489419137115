import select2 from 'select2';
import Handsontable from 'handsontable/dist/handsontable.full.min.js';

App.ProductBuilder = (function () {

  function initNew() {
    initSelect2();
  }

  function initEdit() {
    initHot();
    initProductImagePreview();
    initSelect2();
    add_link_prod_section();
    change_link_prod_section();

    $("#relationship_select").select2({
      tags: true,
      maximumSelectionLength: 1
    });

    App.Form.pikaday('.pikaday');
  }

  function initSelect2() {
    $("select").select2();
  }

  function initPublish() {
    $("select").select2();
  }

  function initProductImagePreview() {
    $('.product-image-preview-links a').on('click', function(e) {
      e.preventDefault();
      var url = $(this).attr("href");
      var html = '<a href="' + url + '" target="_blank"><img src="' + url +  '?w=350&h=350&fit=clip&usm=18"></a>';
      var container = $(this).closest(".product-image-preview");
      container.find(".product-image-preview-image").html(html);
      container.find(".product-image-preview-links a").removeClass('active');
      $(this).addClass('active');
    });

    // select the first one
    $('.product-image-preview-links a:first').click();
  }

  // clone link product section
  function add_link_prod_section() {
    $(".add_prod_relation").on('click', function () {
      var $self = $(this);
      if (($self.prev().find('select')[0].value != '') && ($self.prev().find('.small-5').last().find('input')[0].value != '')) {
        $self.before($self.prev().clone());

        var select_length = $self.prev().find('select').length;
        var previous_relationship = $self.prev().prev();
        var current_relationship = $self.prev();
        current_relationship.find('select')[0].id = "item_link_random";
        current_relationship.find('select')[0].name = "item[link_random][]";
        // previous_relationship.find('input')[0].name = 'link_' + old_value.toLowerCase().split(' ').join('_');
        current_relationship.find('.small-5').last().find('input')[0].name = "link_random_text";
        current_relationship.find('.small-5').last().find('input')[0].id = "link_random_text";
        current_relationship.find('.small-5').last().find('input')[0].value = "";
        current_relationship.find('.small-1').last().find('.center').css("display", "block");

        let new_prd_relation = $('#' + current_relationship.find('select')[0].id + '');
        new_prd_relation.select2({
          tags: true,
          maximumSelectionLength: 1
        });
        // new_prd_relation.find("option:selected").prop("selected", false);
        let prev_prd_relation = $('#' + previous_relationship.find('select')[0].id + '');
        let prev_prd_value = $('#' + previous_relationship.find('select')[0].id + '').val();
        prev_prd_relation.select2();
        prev_prd_relation.select2({
          tags: true,
          maximumSelectionLength: 1
        });
        $('#' + previous_relationship.find('select')[0].id + '').val(prev_prd_value);

        if (new_prd_relation.next().next().find('span').length > 1) {
          new_prd_relation.next().next().remove();
        } else if (new_prd_relation.next().find('span').length > 1) {
          new_prd_relation.next().remove();
        }

        new_prd_relation.val([]);
        new_prd_relation.select2({
          tags: true,
          maximumSelectionLength: 1
        });

        return false;
      } else {
        if ($self.prev().find('select')[0].value == '') {
          alert( 'Please select the last dropdown to proceed');
        } else if ($self.prev().find('.small-5').last().find('input')[0].value == '') {
          alert( 'Please enter the product handle');
        }
        return false;
      }
    });
  }

  // init Handsontable (hot for short)
  function initHot() {
    function hotSizes(query, process) {
      var hot = App.ProductBuilder.hot;
      var allSizes = $("#form_variants").data("sizes");
      var usedSizes = hot.getDataAtCol(0);
      var selectedRange = hot.getSelectedRange()[0];
      var currentSize = hot.getDataAtCell(selectedRange.from.row, selectedRange.from.col)
      process(_.difference(allSizes, _.difference(usedSizes, [currentSize])));
    }

    function afterChange(changes, source) {

      if (changes && source == 'edit') {
        initRowData(changes);
        updateSupplierData(changes);
      }

      updateHotData();
    }

    function initRowData(changes) {
      $.each(changes, function(index) {
        var row = this;
        var dropshipExclude = App.ProductBuilder.hot.getDataAtCell(row[0], 'dropship_exclude');
        var ignorePriceAdders = App.ProductBuilder.hot.getDataAtCell(row[0], 'ignore_price_adders');
        console.log(ignorePriceAdders);
        if ( dropshipExclude == null ) {
          App.ProductBuilder.hot.setDataAtRowProp(row[0], 'dropship_exclude', false, '80s.initRowData');
        }
        if( ignorePriceAdders == null ) {
          App.ProductBuilder.hot.setDataAtRowProp(row[0], 'ignore_price_adders', false, '80s.initRowData');
        }
      });
    }

    function updateSupplierData(changes) {
      var sizeMap = $("#form_variants").data("size-map");
      var supplierSkuPrefix = $("#form_variants").data("supplier-sku-prefix");
      var supplierDescriptionPrefix = $("#form_variants").data("supplier-description-prefix");

      $.each(changes, function(index) {
        var row = this;
        if (row[1] != 'size') { return true; } // only adjust if size changed
        if (row[2] == row[3]) { return true; } // value didn't actually change

        var size = row[3];
        var supplierSize = sizeMap[size];
        if (size == null || supplierSize == null) { return true; }

        if (supplierSkuPrefix) {
          App.ProductBuilder.hot.setDataAtRowProp([
            [row[0], 'supplier_sku', supplierSkuPrefix + supplierSize]
          ], '80s.updateSupplierData');
        }
        if (supplierDescriptionPrefix) {
          App.ProductBuilder.hot.setDataAtRowProp([
            [row[0], 'supplier_description', supplierDescriptionPrefix + supplierSize]
          ], '80s.updateSupplierData');
        }
      });
    }

    function afterRemoveRow(index, amount) {
      updateHotData();
    }

    function updateHotData() {
      if (App.ProductBuilder.hot) {
        var data = JSON.stringify(App.ProductBuilder.hot.getSourceData());
        $("#form_variants").val(data);
      }
    }

    function requiredValidator(value, callback) {
      callback(value ? true : false)
    }

    //check to see if dropship checkbox is checked
    const ds = document.getElementById('item_dropship')
    const dsi = document.querySelector('.dropship-include-checkbox')
    ds.addEventListener('change',function(){
      if(ds.checked){
        //show dropship include checkbox
        showDropshipIncludeCheckbox();
      }else{
        //uncheck and hide dropship include checkbox
        hideAndUncheckDropshipInclude();
      }
    });

    if(ds.checked){
      showDropshipIncludeCheckbox()
    }

    function showDropshipIncludeCheckbox(){
      dsi.classList.remove('hidden');
    }
    function hideAndUncheckDropshipInclude(){
      const dsicb = dsi.querySelector("input[type='checkbox']")
      dsicb.checked = false;
      dsi.classList.add('hidden');
    }
    

    var variants = $("#form_variants");
    var data = JSON.parse(variants.val());
    var hasSize = variants.data("hasSize");
    var existingSizes = _.map(data, function(v) { return v.size });
    var container = document.getElementById("hot_variants");
    var colHeaders = ["Color", "Size", "Body Style", "Supplier Sku", "Supplier Description", "UPC", "Supplier Price", "Price", "Full Price", "Site Price" , "Waist", "Width", "Inseam", "Length", "Weight", "Sleeve Length", "Dropship Exclude", "Ignore Price Adders"];
    var cols = [
      { data: "color", type: "autocomplete" },
      { data: "size", type: "autocomplete", source: hotSizes, strict: true },
      { data: "body_style", type: "autocomplete" },
      { data: "supplier_sku", type: "text", validator: requiredValidator },
      { data: "supplier_description", type: "text" },
      { data: "upc_code", type: "text" },
      { data: "supplier_price", type: "numeric", format: "0.00", validator: requiredValidator },
      { data: "price", type: "numeric", format: "0.00", validator: requiredValidator },
      { data: "full_price", type: "numeric", format: "0.00", validator: requiredValidator },
      { data: "site_price", type: "numeric", format: "0.00", editor: false},
      { data: "waist", type: "numeric", format: "0.00" },
      { data: "width", type: "numeric", format: "0.00" },
      { data: "inseam", type: "numeric", format: "0.00" },
      { data: "length", type: "numeric", format: "0.00" },
      { data: "weight", type: "numeric", format: "0.00" },
      { data: "sleeve_length", type: "numeric", format: "0.00" },
      { data: "dropship_exclude", type: "checkbox", className: "htCenter" },
      { data: "ignore_price_adders", type: "checkbox", className: "htCenter" }
    ];

    App.ProductBuilder.hot = new Handsontable(container, {
      data: data,
      stretchH: 'all',
      colHeaders: colHeaders,
      columns: cols,
      afterChange: afterChange,
      afterRemoveRow: updateHotData,
      minSpareRows: hasSize ? 5 : 0,
      allowInsertRow: hasSize,
      allowRemoveRow: hasSize,
      selectionMode: 'multiple',
    });

    App.ProductBuilder.hot.updateSettings({
      contextMenu: {
        items: {
          "row_above": {},
          "row_below": {},
          "remove_row": {}
        }
      },
    });
    // App.ProductBuilder.hot.validateCells();
  }

  return {
    initNew: initNew,
    initEdit: initEdit,
    initPublish: initPublish
  }
})();
