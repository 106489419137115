App.SupplierPayment = (function () {
  function initFormPayAfter() {
    initNegotiatedInvoiceTotal();
  }

  function initFormPrepaid() {
    initOriginalInvoiceTotal();
  }

  function initNegotiatedInvoiceTotal() {
    $('table#invoice-table tbody input[type=checkbox]').on('change', function(e) {
      payment_total = 0.0;
      $('table#invoice-table tbody input[type=checkbox]').each(function(index) {
        if ($(this)[0].checked) {
          payment_total += parseFloat($(this).data('unpaid-amount'));
        }
      });

      $('#payment-amount').html(payment_total.toFixed(2));
    });
  }

  function initOriginalInvoiceTotal() {
    $('table#invoice-table tbody input[type=checkbox]').on('change', function(e) {
      payment_total = 0.0;
      refund_total = 0.0;
      $('table#invoice-table tbody input[type=checkbox]').each(function(index) {
        if ($(this)[0].checked) {
          payment_total += parseFloat($(this).data('original-amount'));
          refund_total += parseFloat($(this).data('original-amount')) - parseFloat($(this).data('negotiated-amount'));
        }
      });

      $('#payment-amount').html(payment_total.toFixed(2));
      $('#refund-amount').html(refund_total.toFixed(2));

      if (refund_total == 0) {
        $('#supplier_payment_refund_form').attr('disabled', 'disabled');
        $('#supplier_payment_refund_form').removeAttr('required');
        $('form#new_supplier_payment input[type=submit]').attr('disabled', 'disabled');
      } else {
        $('#supplier_payment_refund_form').attr('required', 'true');
        $('#supplier_payment_refund_form').removeAttr('disabled');
        $('form#new_supplier_payment input[type=submit]').removeAttr('disabled');
      }
    });
  }

  return {
    initFormPayAfter: initFormPayAfter,
    initFormPrepaid: initFormPrepaid
  }
})();
