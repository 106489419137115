App.ShipmentsReport = (function () {

  function init() {
    App.Form.pikaday('.pikaday');
  }

  return {
    init: init
  }
})();
