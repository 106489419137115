App.Binnings = (function () {
  var init = function() {
    $("#auto-fill-quantities").on("click", autoFillQuantities);

    App.watch('input[data-backorder-status-id]', function(el) {
      updateBackorderStatus(el);
    });
    // update all backorder status on init
    $('input[data-backorder-status-id]').each(function(index) { updateBackorderStatus(this); });
  };

  var autoFillQuantities = function(e) {
    e.preventDefault();

    vex.dialog.confirm({
      message: "Are you sure you want to set all slip quantities to the PO quantities?",
      callback: function(value) {
        if (value) {
          $("input[data-order-quantity]").each(function(i,e) {
            var el = $(e);
            el.val(el.data('order-quantity'));
          });
        }
      }
    });
  }

  var updateBackorderStatus = function(el) {
    el = $(el);
    var backorderStatusId = '#' + el.data('backorder-status-id');
    var $backorderEl = $(backorderStatusId);
    if (el.val() != '' && el.val() < el.data('unreceived-quantity')) {
      // enable backorder status fields
      $backorderEl.removeClass('disabled');
      $backorderEl.find('input').prop('disabled', false);
    } else {
      $backorderEl.addClass('disabled');
      $backorderEl.find('input').prop('disabled', true);
    }
  };

  return {
    init: init
  };
})();
