import Pikaday from 'pikaday'

App.Form = (function () {
  function pikaday(el) {
    $(el).each(function(index) {
      new Pikaday({
        field: this,
        format: "YYYY-MM-DD",
        onSelect: function() {
        }
      });
    });
  }
  function preventDoubleSubmit() {
    $(document).on("submit", "form", function() {
      $('input[type=submit]', this).attr('disabled', 'disabled');
    });
  }
  return {
    pikaday: pikaday,
    preventDoubleSubmit: preventDoubleSubmit
  }
})();
