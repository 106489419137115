App.Replenish = (function () {

  function initShow() {
    App.ItemLocation.initActions();
  }

  return {
    initShow: initShow
  }
})();
