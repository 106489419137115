App.Picking = function() {
  initShow = function() {
    $('#mark-button').on('click', function(e) {
      e.preventDefault();
      submitForm(this);
    });

    $('#pick-button').on('click', function(e) {
      e.preventDefault();
      submitForm(this);
    });

    // make sure the checkbox matches the total
    $(document).on("click", ".pick-count input", function(e) {
      var el = $(this);
      var total = Number.parseInt(el.data('total'));
      if (el.is(":checked") && total > 1) {
        e.preventDefault();
        promptTotal(total, function() {
          el.prop("checked", true);
        });
      }
    });

    promptToGetPickBin();
  }

  submitForm = function(button) {
    var form = $(button).closest('form');
    // grab all the checked boxes and add them to this form
    form.find('.selected_item').remove();
    $('.pick-count input:checked').each(function(i, e) {
      var el = $(e);
      form.append('<input type="hidden" name="selected[]" value="' + el.val() + '" class="selected_item">');
    });
    form.submit();
  }

  promptTotal = function(total, callback) {
    var promptForm = $("#prompt-total-form").html();
    vex.dialog.open({
      message: 'Please confirm the number of units for this slot',
      input: promptForm,
      callback: function(data) {
        if (data === false) {
          return false;
        } else if (data.total != total) {
          vex.dialog.alert("The number of units does not match. Please try again.")
          return false;
        }
        callback();
        return true;
      }
    });
  }

  promptToGetPickBin = function() {
    var alert = $('[data-get-pick-bin-alert]');
    if (alert.length > 0) {
      // prompt for terms
      vex.dialog.alert({ unsafeMessage: alert.data('get-pick-bin-alert') });
    }
  }

  return {
    initShow: initShow
  }
}();
