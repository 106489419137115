import Pikaday from 'pikaday'
App.ItemInventoryValue = (function () {
  function init() {
    var picker = new Pikaday({
      field: $('.pikaday')[0],
      format: "YYYY-MM-DD",
      onSelect: function() {
        var d = this.getMoment().format("YYYY-MM-DD");
        var el = $(this._o.field);
        document.location = el.data("url") + "?" + $.param({date: d})
      }
    });
  }

  return {
    init: init
  }
})();
