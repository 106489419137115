App.Shipments = (function () {

var currentHref = window.location.href;  

  var currentCarrier = null;
  var initIndex = function() {
    cursorFocus(".autofocus:first");

    const noteLinks = document.querySelectorAll('.add_note')
    for(link of noteLinks){
      link.addEventListener('click',function(e){
        e.preventDefault();
        let relatedNoteArea = link.parentNode.parentNode.querySelector('.note_area')
        relatedNoteArea.classList.remove('hidden')
        relatedNoteArea.querySelector('textarea').focus()
        link.classList.add('hidden')
      })
    }
    
  }
  var initList = function() {
    cursorFocus(".autofocus:first");
  }

  // Inits the New Shipment form
  var initNew = function() {
    cursorFocus(".autofocus:first");
    $('select[name="shipment[shipping_code]"]').on('change', updateFormWithEvent);
    $('form.shipment-form').each(function() {
      updateForm($(this));
    });
    insuranceToggle();
    $('input[name="shipment_insurance_toggle"]').on('change', insuranceToggle);

    App.OrderNote.init();

    var notes = $("[data-prompt=order-notes]");
    var cHref = window.location.href.split('/');
    var matchOnNew = /^new/;

    if (notes.length > 0 && !cHref[cHref.length-1].match(matchOnNew)) {
      showOrderNotes($('<div/>').append(notes.clone()).html());
    }

    var shippingAlert = $('[data-shipping-alert]');
    if (shippingAlert.length > 0) {
      // prompt for terms
      vex.dialog.alert({ unsafeMessage: shippingAlert.data('shipping-alert') });
    }
    
      var shipmentForms = document.querySelectorAll('.shipment-form'); 

      for(let form of shipmentForms){
        let override = form.querySelector('#override_international_ship');
        let printLabelWrapper = form.querySelector('#print_label');
        if(override != null){
          console.log('override message present, disabling enter key for form submission')
          form.addEventListener('keypress', function(event) {
            if (event.key === 'Enter') {
                event.preventDefault(); // Prevent form submission
                if(override.checked){
                  document.querySelector('.shipment-form').submit();
                 }else{
                  alert('This is a partial order. Please select the shipment override checkbox to proceed.')
                 }
            }
          });
  
          if(override){
            printLabelWrapper.classList.add('hidden')
          }
          override.addEventListener('click',function(e){
           if(override.checked){
            printLabelWrapper.classList.remove('hidden');
           }else{
            printLabelWrapper.classList.add('hidden')
           }
          })
        }

      }
    initAddPackages();
  }

  var initShow = function() {
    var form = $(".shipping-label-form");
    if (form.data('autoprint')) {
      form.submit();
    }
  }

  var getShippingCodes = function() {
    return $('#shipping-data').data('shipping-code');
  }

  var getShipmentPackagingTypes = function() {
    return $('#shipping-data').data('packaging-types');
  }

  // Gets the selected shipping method and returns the full OMX object
  var getShippingMethod = function(form) {
    var selectedId = form.find('select[name="shipment[shipping_code]"] option:selected').val()

    var shippingCode = $.grep(getShippingCodes(), function(ele, i) {
      return ele.code.toString() == selectedId;
    })

    if (shippingCode) {
      return shippingCode[0];
    } else {
      return null
    }
  }

  // Update the form based on user interaction
  var updateFormWithEvent = function(event) {
    var form = $(event.target).parents('form');
    updateForm(form);
  }

  // Update the form
  var updateForm = function(form) {
    var shippingCode = getShippingMethod(form);

    if (shippingCode) {
      switch (shippingCode.carrier) {
      case "fedex":
        showFedexForm(form);
        break;
      case "ups": // UPS maps to FedEx
        showUPSForm(form);
        break;
      case "usps":
        showUSPSForm(form);
        break;
      }
    }
  }

  // Update the form for a FedEx shipping code
  var showFedexForm = function(form) {
    // Insurance
    form.find('input[name="shipment_insurance_toggle"]').prop('disabled', false);
    // Signature
    var shippingMethod = getShippingMethod(form);
    if (shippingMethod.code.toString() == "FEDEX_GROUND") {
      form.find('select[name="shipment[signature_type]"]').val('DIRECT');
      form.find('select[name="shipment[signature_type]"]').prop('disabled', true);
    } else {
      form.find('select[name="shipment[signature_type]"]').val('NO_SIGNATURE_REQUIRED');
      form.find('select[name="shipment[signature_type]"]').prop('disabled', false);
    }

    // Packaging Types
    showPackagingTypesFor('fedex', form);

    // Airway bill
    toggleAirwaybill(form);

    // FedEx One Rate
    form.find('input[name="shipment[fedex_one_rate]"]').prop('disabled', false);
  }

    // Update the form for a UPS shipping code
    var showUPSForm = function(form) {
      form.find('.ups-row2').show();
      form.find('input[name="shipment_insurance_toggle"]').prop('disabled', true).prop('checked', false);
      form.find('input[name="shipment[insured_value]"]').prop('disabled', true).val('');
      // Signature
      form.find('select[name="shipment[signature_type]"]').prop('disabled', true);
      
      // FIMS Airway Bill
      toggleAirwaybill(form);
  
      // Packaging Types
      showPackagingTypesFor('ups', form);
  
      // Airway bill
      toggleAirwaybill(form);
  
      // FedEx One Rate
      form.find('input[name="shipment[fedex_one_rate]"]').prop('disabled', true).prop('checked', false);
    }

  // Update the form for a USPS shipping code
  var showUSPSForm = function(form) {
    // Insurance
    form.find('input[name="shipment_insurance_toggle"]').prop('disabled', true).prop('checked', false);
    form.find('input[name="shipment[insured_value]"]').prop('disabled', true).val('');
    // Signature
    form.find('select[name="shipment[signature_type]"]').prop('disabled', true);
    // FIMS Airway Bill
    toggleAirwaybill(form);

    // FedEx One Rate
    form.find('input[name="shipment[fedex_one_rate]"]').prop('disabled', true).prop('checked', false);

    // Packaging Types
    var shippingMethod = getShippingMethod(form);
    showPackagingTypesFor('stamps', form);
  }

  // FIMS Airway bill
  var toggleAirwaybill = function(form) {
    var shippingMethod = getShippingMethod(form);
    var upsRow = form.find('.row-ups');
    var upsInput = upsRow.find('input');
    var fimsRow = form.find('.row-fims');
    var fimsInput = fimsRow.find('input');
    var fedRow = form.find('.row-fed');

      // Ensure all rows are initially hidden; not strictly necessary as HTML has inline styles, but good for clarity
      upsRow.hide();
      fimsRow.hide();
      fedRow.hide();
  console.dir(shippingMethod);
      // Toggle visibility and disabled state based on shipping method
      if (shippingMethod.code.toString() == "UPS_WORLDWIDE_ECONOMY") {
          upsRow.show();
          upsInput.prop('disabled', false).val(upsInput.data('airway-bill'));
          fimsInput.prop('disabled', true).val('');
      } else if (shippingMethod.code.toString() == "MAIL_INTERNATIONAL") {
          fimsRow.show();
          fimsInput.prop('disabled', false).val(fimsInput.data('airway-bill'));
          upsInput.prop('disabled', true).val('');
          fedRow.show();
          //else if shippingMethod contains the the word fedex (case insensitive) show fed rows
      } else if (shippingMethod.name.toString().toLowerCase().indexOf('fedex') !== -1) {
          fedRow.show();
      }else {
          // In case no valid method is selected, keep all hidden
          upsInput.prop('disabled', true).val('');
          fimsInput.prop('disabled', true).val('');
          fedRow.hide();
      }
  }

  // Enables all of the packaging types for the carrier in the form,
  // and disabled all of the invalid ones.
  var showPackagingTypesFor = function(carrier, form) {
    form.find('select[name="shipment[packaging_type]"] option').each(function() {
      var option = $(this);
      var thisPackagingType = $.grep(getShipmentPackagingTypes(), function(ele, i) {
        return ele.id.toString() == option.val().toString();
      })
      if (thisPackagingType && thisPackagingType[0].carrier == carrier) {
        option.prop('disabled', false);
      } else {
        option.prop('disabled', true);
      }
    });
    if (currentCarrier != carrier) {
      selectDefaultPackagingTypeFor(carrier, form);
      currentCarrier = carrier;
    }
  }

  var selectDefaultPackagingTypeFor = function(carrier, form) {
    switch (carrier) {
    case "fedex":
      var defaultValue = "YOUR_PACKAGING";
      break;
    case "ups": // UPS maps to FedEx
      var defaultValue = "02";
      break;
    case "stamps":
      var defaultValue = "Package";
      break;
    case "usps": // International only
      var defaultValue = "VARIABLE";
      break;
    default:
      var defaultValue = null;
    }

    if (defaultValue) {
      form.find('select[name="shipment[packaging_type]"] option[value="'+ defaultValue +'"]').prop('selected',true);
    }
  }

  // Handles the Insurance check box
  var insuranceToggle = function(event) {
    if ($('input[name="shipment_insurance_toggle"]').is(':checked')) {
      $('input[name="shipment[insured_value]"]').prop('disabled', false);
    } else {
      $('input[name="shipment[insured_value]"]').prop('disabled', true).val('');
    }
  }

  var cursorFocus = function(elem) {
    var x = window.scrollX, y = window.scrollY;
    $(elem).focus();
    window.scrollTo(x, y);
  }

  var showOrderNotes = function(notes) {
    vex.dialog.alert({ unsafeMessage: '<h3>Review the Order Notes</h3>' + notes });
  }

  var initAddPackages = function() {
    $("#js-add-packages").on("click", function() {
      var container = $("#js-package-section-container");
      var count = container.find(".package-section").length;
      for(var i = 1; i < 6; i++) {
        var tmpl = $($("#js-package-template").html());
        var label = $(tmpl.find(".js-package-weight-label"));
        var text = label.text().replace("NUM", count+i);
        label.text(text);
        container.append(tmpl);
      }
    });
  }

  //simple functionality that keeps track of current href and scroll position, to return user to that position after printing a label or leaving and returning to the same shipping page
  if(currentHref.includes('/shipments/')){
    var scrollStop=function(n){var o;n&&"function"==typeof n&&window.addEventListener("scroll",function(t){window.clearTimeout(o),o=setTimeout(function(){n()},66)},!1)};
    var listedShipmentSessionStorage = sessionStorage.getItem('current_shipment_href');

    if(listedShipmentSessionStorage != null){
      if(listedShipmentSessionStorage == currentHref){
        //force blur of autofocus 
        var elelist = document.getElementsByTagName("input");
        for(var i = 0; i < elelist.length; i++){
            elelist[i].blur();
        }
        var scrlTo = sessionStorage.getItem('current_scroll_position');
        //get saved scroll positon and scroll to that position after 200ms delay
        setTimeout(function(){ document.documentElement.scrollTop = scrlTo}, 600)
      }else{
        // Set current shipment page to session storage
        setCurrentSessionHref()
      }
    }else{
      // Set current shipment page to session storage
      setCurrentSessionHref()
    }  

    function setCurrentSessionHref(){
      sessionStorage.setItem('current_shipment_href',currentHref)
      sessionStorage.setItem('current_scroll_position',document.documentElement.scrollTop)
    }

    scrollStop(function () {
      sessionStorage.setItem('current_scroll_position',document.documentElement.scrollTop)
    });
  }

  $(document).on("click", "#fetch_rates_call", function(event){
    var carriers = [];
    var order_id = $("#order_id").val();
    if ($("#ups").is(":checked")) {
      carriers.push("UPS");
    }
    if ($("#usps").is(":checked")) {
      carriers.push("USPS");
    }
    if ($("#fedex").is(":checked")) {
      carriers.push("FedEx");
    }

    $("#overlayDiv_new").css('display', 'block');
    var ship_method = $("#shipment_shipping_code").find(":selected").text();
    var package_type = $("#shipment_packaging_type").find(":selected").text();
    var package_weight = $("#shipment_package_weights_").val();
    var package_length = $("#shipment_package_lengths_").val();
    var package_width = $("#shipment_package_widths_").val();
    var package_height = $("#shipment_package_heights_").val();

    var packages = {weight:package_weight, length: package_length, width: package_width, height: package_height};

    $.ajax({
      type: "GET",
      url: '/shipments/fetch_rates',
      data: { order_id: order_id, carriers : carriers, packages : packages},
      success: function(response) {
        $("#overlayDiv_new").css('display', 'none');
        $("#get-price-modal").click();
        $(".price-section").show();
        // $("#price-section").hide();
        var rowlength = $("#price-popup").find('tr').length;
        for(var i = 1; i < rowlength; i++){
          $('#price-popup tr:last').remove();
        }
        var append_content = '';
        for (let key in response) {
          if (response.hasOwnProperty(key)) {
            for (let inside_key in response[key])
            $('#price-popup tr:last').after(
              '<tr><td>' + key.toUpperCase() + '</td><td>'
              + inside_key + '</td><td>' + ((inside_key == 'errors') ? '' : ' $')
              + response[key][inside_key] + '</td></tr>'
              );
          }
        }
      }
    });
  });

  $(document).on("click", "#update_shipping_line_status", function(event){
    var dso_id = $("#dso_id").val();
    var line_item_ids = $("#line_item_ids").val().split(' ');
    var order_id = $("#shipment_order_id").val();
    var shipment_status = {};

    for (var i = 0; i < line_item_ids.length; i++) {
      var li_id = line_item_ids[i];
        shipment_status[li_id] = {};
        shipment_status[li_id]['shipment_li_status'] = $("#shipment_"+li_id+"_shipment_li_status").val();
        shipment_status[li_id]['shipment_li_location'] = $("#shipment_"+li_id+"_location").val();
    }
    $.ajax({
      type: "POST",
      url: '/shipments/update_shipment_li_status',
      data: { dso_id: dso_id, line_item_ids : line_item_ids, shipment_status: shipment_status, order_id: order_id},
      success: function(response) {
        $(".ship_close").click();
        App.Alert.show("Successfully uploaded", 'notice');
        setTimeout(function(){
          location.reload();
        },2000);
      }
    });
  });

  $(document).on("click", "#save_shipping_note", function(event){
    var ord_id = $(this).parent().find('[id^="order_id"]')[0].id.split('_');
    var order_id = $("#order_id_"+$(ord_id).get(-1)).val();

    // var dso_id = $(this).parent().find('[id^="dso_id"]')[0].id.split('_');
    // var dropship_order_id = $("#dso_id_"+$(dso_id).get(-1)).val();

    var shipping_note = $("#shipping_note_"+$(ord_id).get(-1)).val();
    var is_shipment_note = $("#is_shipment_note_"+$(ord_id).get(-1))[0].checked;
    $.ajax({
      type: "GET",
      url: '/shipments/create_shipping_note',
      data: { order_id: order_id, note : shipping_note, is_shipment_note: is_shipment_note},
      success: function(response) {
        $(".shipping_note_close").click();
        App.Alert.show("Shipping note created successfully", 'notice');
      }
    });
  });

  $(document).keydown(function(event) {
    if (event.keyCode == 27) {
      var hold_ship_modal = document.getElementById("hold_shipment");
      hold_ship_modal.style.display = "none";
    }
  });


  return {
    initNew: initNew,
    initIndex: initIndex,
    initShow: initShow
  };
})();
