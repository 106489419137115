App.Dropdown = (function() {

  function init() {
    let dd = document.querySelectorAll('button[data-dropdown]')
    for(let i=0;i<dd.length;i++){
      dd[i].addEventListener('click',function(){
        //if close, open
        if(dd[i].getAttribute('aria-expanded') == 'false'){
          dd[i].setAttribute('aria-expanded','true');
          var dropdown = dd[i].parentNode.querySelector('ul[data-dropdown-content]')
          dropdown.setAttribute('aria-hidden','false');
          dropdown.style.left = 'auto';
        }else{
          dd[i].setAttribute('aria-expanded','false')
          var dropdown = dd[i].parentNode.querySelector('ul[data-dropdown-content]')
          dropdown.setAttribute('aria-hidden','true');
          dropdown.style.left = '-9999px';
        }
      });
    }
  }

  return {
    init: init
  }
})();
