App.MoveCart = (function () {

  function initShow() {
    App.ItemLocation.initActions();
    // enable cancel form
    $(document).on("click", "[data-action=move-cart-item-cancel]", function(e) {
      e.preventDefault();
      url = $(this).data('url');
      openCancelForm(url);
    })
  }

  function openCancelForm(url) {
    $.get(url, function(data) {
      vex.open({
        unsafeContent: data,
        overlayClosesOnClick: false
      });
    });
  }

  return {
    initShow: initShow
  }
})();
