App.PurchaseOrderExceptionItem = (function () {
  function initShow() {
    initNoScroll();
    initActions();
  }

  function initIndex() {
    initActions();
    initCheckAllCheckbox();
  }

  function initNoScroll() {
    $('select.noscroll').on('change', function(e) { $(this).blur(); });
  }

  function initCheckAllCheckbox() {
    $('#check_all_checkboxes').on('change', function(e) {
      $('table#purchase_order_exception_items tbody input[type=checkbox]').prop('checked', $(this)[0].checked);
    });
  }

  function initActions() {
    $(document).on('click', '[data-action="open-form"]', function(e) {
      e.preventDefault();
      openForm($(this));
    });
  }

  function openForm(el) {
    var width = el.data("width") || "1000px";

    var purchase_order_exception_item_ids = el.data("purchase-order-exception-ids");
    if (!purchase_order_exception_item_ids) {
      var checked_checkboxes = $('table#purchase_order_exception_items tbody input[type=checkbox]:checked')
      if (checked_checkboxes.length == 0) {
        alert('You must select at least one PO Exception');
        return;
      }
      purchase_order_exception_item_ids = $.map(checked_checkboxes, function(e,i) {
        return e.dataset.purchaseOrderExceptionItemId
      });
    }
    // Note that this assumes the url parameter already contains a query string, to which it appends
    var url = el.data("url") + '&' + $.param({purchase_order_exception_item_ids: purchase_order_exception_item_ids.join()});
    $.get(url, function(data) {
      vex.open({
        unsafeContent: data,
        contentCSS: {width: width},
        overlayClosesOnClick: false
      });
    });
  }

  return {
    initShow: initShow,
    initIndex: initIndex
  }
})();


